import React from 'react'

// IMAGES IMPORTS
import nextButton from '../assets/fixed-images/next-button.svg'
import nextButtonWhite from '../assets/fixed-images/arrow-white.svg'

const WorkCard = (props) => {
  return (
    <div className="py-5 position-relative">
      <div className="d-flex flex-column align-items-center py-5 work-card-content">
        <h3 className="text-white text-center mb-0 text-capitalize">
          {props.title}
        </h3>
        <p className="text-center text-white mb-0 pb-1">{props.text}</p>
        <div className="work-card-button d-lg-block d-none pb-1">
          <img
            src={nextButton}
            alt="arrow"
            className="contain work-card-arrow"
          />
          <img
            src={nextButtonWhite}
            alt="arrow"
            className="contain work-card-arrow-white"
          />
        </div>
        <img
          src={nextButton}
          alt="arrow"
          className="contain work-card-arrow d-lg-none"
        />
        <div className="position-absolute top-0 start-0 h-100 w-100 ratio back">
          <img src={props.image} alt="background" className="cover" />
          <div className="work-card-gradient"></div>
        </div>
      </div>
    </div>
  )
}

export default WorkCard
