import React, { Fragment, useContext, useEffect, useState } from "react";

// SWIPER IMPORTS
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import "swiper/css/grid";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Grid, EffectFade, Autoplay } from "swiper";

// CUSTOM COMPONENTS
import Layout from "../components/Layout";
import WWDCard from "../components/whatWeDoCard";
import RelatedWork from "../components/RelatedWork";
import TitleDevider from "../components/TitleDevider";
import { Link } from "react-router-dom";

// IMAGES IMPORTS
import nextButton from "../assets/fixed-images/next-button.svg";

import GlobalState from "../contexts/GlobalState";
import Api from "../components/api";
import { Helmet } from "react-helmet";

const Home = () => {
  const { generalDataGlobal } = useContext(GlobalState);
  const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
  const [sliderContent, setSliderContent] = useState();
  const [wwdContent, setWwdContent] = useState();
  const [workContent, setWorkContent] = useState();
  const [clientsContent, setClientsContent] = useState();
  const [loader, setLoader] = useState(false);
  const [currentImage, setCurrentImage] = useState();
  const [currentVideo, setCurrentVideo] = useState();
  const [index, setIndex] = useState(0);

  // ANIMATION INITIALIZATION
  useEffect(() => {
    window.triggerScroll();
    setLoader(true);
    setGeneralDataApiOptions({
      url: "general",
      callback: (res) => {
        setSliderContent(res.data.home_slider);
        setWwdContent(res.data.home_services);
        setWorkContent(res.data.home_projects);
        setClientsContent(res.data.clients);
        setLoader(false);
        if (res.data.home_slider[0].image)
          setCurrentImage(
            process.env.REACT_APP_BASE_URL + res.data.home_slider[0].image
          );

        if (res.data.home_slider[0].video)
          setCurrentVideo(
            process.env.REACT_APP_BASE_URL + res.data.home_slider[0].video
          );
      },
    });
  }, []);

  const sendId = (index) => {
    if (generalDataGlobal[0]?.home_slider[index]?.image)
      setCurrentImage(
        process.env.REACT_APP_BASE_URL +
          generalDataGlobal[0]?.home_slider[index]?.image
      );
    else if (generalDataGlobal[0]?.home_slider[index]?.video)
      setCurrentVideo(
        process.env.REACT_APP_BASE_URL +
          generalDataGlobal[0]?.home_slider[index]?.video
      );

    setIndex(index);
  };

  return (
    <Fragment>
      <Api options={generalDataApiOptions} />
      {loader ? (
        <div className="custom-loader middle-page"></div>
      ) : (
        <Layout active="Home">
          <Helmet>
            <title>{generalDataGlobal[0]?.seo_pages[0].title}</title>
            <meta
              name="title"
              content={generalDataGlobal[0]?.seo_pages[0].title}
            />
            <meta
              name="description"
              content={generalDataGlobal[0]?.seo_pages[0].description}
            />
            <meta
              property="og:title"
              content={generalDataGlobal[0]?.seo_pages[0].title}
            />
            <meta
              property="og:description"
              content={generalDataGlobal[0]?.seo_pages[0].description}
            />
            <meta
              property="og:image"
              content={generalDataGlobal[0]?.seo_pages[0].image}
            />
            <meta
              property="twitter:title"
              content={generalDataGlobal[0]?.seo_pages[0].title}
            />
            <meta
              property="twitter:description"
              content={generalDataGlobal[0]?.seo_pages[0].description}
            />
            <meta
              property="twitter:image"
              content={generalDataGlobal[0]?.seo_pages[0].image}
            />
          </Helmet>
          <div className="position-relative home-banner overflow-hidden">
            {generalDataGlobal[0]?.home_slider[index]?.image ? (
              <img
                src={currentImage}
                className="position-absolute h-100 w-100 home-banner-image"
                alt="home-banner"
              ></img>
            ) : generalDataGlobal[0]?.home_slider[index]?.video ? (
              <video
                className="position-absolute video-banner-image"
                playsInline
                autoplay="true"
                muted
              >
                
                <source src={currentVideo} type="video/mp4" />
              </video>
            ) : null}
            <Swiper
              pagination={{
                clickable: true,
                el: ".home-banner-dots",
              }}
              onSlideChange={(e) => sendId(e.realIndex)}
              grabCursor={true}
              effect="fade"
              fadeEffect={{
                crossFade: true,
              }}
              spaceBetween={5}
              modules={[Pagination, EffectFade, Autoplay]}
              autoplay={{
                delay: 8000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              className="home-banner-slider"
            >
              {sliderContent?.map((link, index) => (
                <SwiperSlide key={index}>
                  <div className="container">
                    <div className="row">
                      <div className="home-banner-slider-text">
                        <h3 className="mb-0 text-break">{link.title_1}</h3>
                        <h2 className="text-break">{link.title_2}</h2>
                        <h2 className="regular-home mb-0 text-break">
                          {link.title_3}
                        </h2>
                        <div className="d-flex d-lg-none position-relative mt-4 w-fit home-banner-show-button">
                          <div className="home-banner-button d-flex pointer">
                            <p className="text-white text-uppercase my-auto mx-4">
                              {link.button_text}
                            </p>
                          </div>
                          <div className="ratio ratio-1x1 home-banner-button-img flex-shrink-0">
                            <img
                              src={nextButton}
                              alt="next"
                              className="contain ms-1"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <a href={link.url} className="text-decoration-none">
                    <div className="d-lg-flex d-none position-relative mt-4 w-fit home-banner-show-button">
                      <div className="home-banner-button d-flex justify-content-end pointer">
                        <p className="text-white text-uppercase my-auto text-end">
                          {link.button_text}
                        </p>
                      </div>

                      <div className="ratio ratio-1x1 home-banner-button-img flex-shrink-0">
                        <img
                          src={nextButton}
                          alt="next"
                          className="contain ms-1"
                        />
                      </div>
                    </div>
                  </a>
                </SwiperSlide>
              ))}
            </Swiper>

            <div className="container front position-relative">
              <div className="row">
                <div className="col-12"></div>
              </div>
              <div className="row justify-content-between home-banner-icons">
                <div className="d-flex align-items-end justify-content-between">
                  <a
                    href={
                      "https://wa.me/" +
                      generalDataGlobal[0]?.home_settings.chat_number
                    }
                    target="_blank"
                    rel="noreferrer noopener"
                    className="w-auto"
                  >
                    <div className="d-flex align-items-center">
                      <div className="home-banner-contact-icon flex-shrink-0 d-flex justify-content-center align-items-center">
                        <img
                          src={
                            process.env.REACT_APP_BASE_URL +
                            "" +
                            generalDataGlobal[0]?.home_settings.chat_icon
                          }
                          alt="whatsapp"
                          className="contain"
                        />
                      </div>
                      <div className="flex-shrink-0 d-sm-block d-none">
                        <p className="home-banner-social-text text-uppercase mb-0">
                          {generalDataGlobal[0]?.home_settings.chat_label}
                        </p>
                      </div>
                    </div>
                  </a>
                  <div className="home-banner-dots text-center w-fit"></div>
                  <a
                    href={
                      "tel:" + generalDataGlobal[0]?.home_settings.phone_number
                    }
                  >
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0 d-sm-block d-none">
                        <p className="home-banner-social-text-right text-uppercase mb-0">
                          {generalDataGlobal[0]?.home_settings.phone_label}
                        </p>
                      </div>
                      <div className="home-banner-contact-icon flex-shrink-0 d-flex justify-content-center align-items-center">
                        <img
                          src={
                            process.env.REACT_APP_BASE_URL +
                            "" +
                            generalDataGlobal[0]?.home_settings.phone_icon
                          }
                          alt="phone"
                          className="contain"
                        />
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* WHAT WE DO */}

          {wwdContent?.length > 0 ? (
            <div className="py-lg-5" animate="down">
              <div className="container py-5 my-5">
                <div className="row">
                  <TitleDevider
                    title={generalDataGlobal[0]?.home_settings.what_we_do_title}
                  />
                </div>
                <div className="d-flex position-relative justify-content-center align-items-center home-sec1">
                  <div className="slider-prev-button me-4 flex-shrink-0"></div>
                  <div className="col-xl-11 col-md-10 col-sm-9 col-6">
                    <Swiper
                      navigation={{
                        nextEl: ".slider-next-button",
                        prevEl: ".slider-prev-button",
                      }}
                      spaceBetween={20}
                      slidesPerView={4}
                      autoplay={{
                        delay: 10000,
                        disableOnInteraction: false,
                        pauseOnMouseEnter: true,
                      }}
                      modules={[Navigation, Autoplay]}
                      breakpoints={{
                        0: {
                          slidesPerView: 1,
                          // spaceBetween: 20,
                        },
                        575.9: {
                          slidesPerView: 2,
                          // spaceBetween: 40,
                        },
                        991.9: {
                          slidesPerView: 3,
                          // spaceBetween: 40,
                        },
                        1199.9: {
                          slidesPerView: 4,
                          // spaceBetween: 50,
                        },
                      }}
                      className="home-wwd-slider"
                    >
                      {wwdContent?.map((link, index) => (
                        <SwiperSlide key={index}>
                          <Link to={"/what-we-do/" + link.slug}>
                            <WWDCard
                              image={process.env.REACT_APP_BASE_URL + link.icon}
                              text_1={link.title}
                              text_2={link.excerpt}
                            />
                          </Link>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                  <div className="slider-next-button ms-4 flex-shrink-0"></div>
                </div>
              </div>
            </div>
          ) : null}

          {/* OUR WORK */}
          {workContent?.length > 0 ? (
            <RelatedWork
              title={generalDataGlobal[0].home_settings.our_work_title}
              bgImage={
                process.env.REACT_APP_BASE_URL +
                generalDataGlobal[0].home_settings.our_work_bg
              }
              workContent={workContent}
            />
          ) : null}

          {clientsContent?.length > 0 ? (
            <div className="py-5 oc-container">
              <div className="container py-5 my-lg-5" animate="left">
                <div className="row">
                  <TitleDevider
                    title={
                      generalDataGlobal[0]?.home_settings.our_clients_title
                    }
                  />
                </div>
                <div className="row justify-content-center home-sec1 d-sm-flex d-none">
                  <div className="col-xl-11 col-md-10 col-sm-9 col-6 position-relative">
                    <div className="slider-prev-button-oc"></div>
                    <Swiper
                      slidesPerView={6}
                      grid={{
                        rows: 2,
                      }}
                      spaceBetween={30}
                      pagination={{
                        clickable: true,
                      }}
                      navigation={{
                        nextEl: ".slider-next-button-oc",
                        prevEl: ".slider-prev-button-oc",
                      }}
                      modules={[Grid, Navigation, Autoplay]}
                      autoplay={{
                        delay: 10000,
                        disableOnInteraction: false,
                        pauseOnMouseEnter: true,
                      }}
                      breakpoints={{
                        0: {
                          slidesPerView: 2,
                          // spaceBetween: 20,
                        },
                        575.9: {
                          slidesPerView: 3,
                          // spaceBetween: 40,
                        },
                        767.9: {
                          slidesPerView: 4,
                          // spaceBetween: 40,
                        },
                        991.9: {
                          slidesPerView: 5,
                          // spaceBetween: 40,
                        },
                        1199.9: {
                          slidesPerView: 6,
                          // spaceBetween: 50,
                        },
                      }}
                      className="home-oc-slider"
                    >
                      {clientsContent?.map((link, index) => (
                        <SwiperSlide key={index}>
                          <div className="oc-border ratio ratio-1x1">
                            <img
                              src={process.env.REACT_APP_BASE_URL + link?.image}
                              alt="logo"
                              className="contain"
                            />
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                    <div className="slider-next-button-oc"></div>
                  </div>
                </div>
                <div className="d-flex position-relative justify-content-center align-items-center home-sec1 d-sm-none">
                  <div className="slider-prev-button-oc-mob me-4 flex-shrink-0"></div>
                  <div className="col-xl-11 col-md-10 col-sm-9 col-6">
                    <Swiper
                      navigation={{
                        nextEl: ".slider-next-button-oc-mob",
                        prevEl: ".slider-prev-button-oc-mob",
                      }}
                      spaceBetween={20}
                      slidesPerView={1}
                      modules={[Navigation, Autoplay]}
                      autoplay={{
                        delay: 10000,
                        disableOnInteraction: false,
                        pauseOnMouseEnter: true,
                      }}
                      className="home-wwd-slider"
                    >
                      {clientsContent?.map((link, index) => (
                        <SwiperSlide key={index}>
                          <div className="oc-border ratio ratio-1x1">
                            <img
                              src={process.env.REACT_APP_BASE_URL + link.image}
                              alt="logo"
                              className="contain"
                            />
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                  <div className="slider-next-button-oc-mob ms-4 flex-shrink-0"></div>
                </div>
              </div>
            </div>
          ) : null}
        </Layout>
      )}
    </Fragment>
  );
};

export default Home;
