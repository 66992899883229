import { useState } from 'react'
import Particles from 'react-tsparticles'
import BubblesBackground from './BubblesBackground'

// HUMAN SVG
import humanImg from '../assets/fixed-images/personindots-058.svg'

export default function BubblesBackgroundWrapper(props) {
  const [animationOpacity, setAnimationOpacity] = useState(0)
  
  return (
    <div style={{ opacity: animationOpacity, transition: '0.5s', zIndex: -1 }}>
      <BubblesBackground setOpacity={setAnimationOpacity} white={props.white} />
      {props.withHuman && (
        <Particles
          className='bubbleman-margin'
          id="particles2"
          options={{
            detectRetina: false,
            pauseOnBlur: true,
            pauseOnOutsideViewport: true,
            fpsLimit: 61,
            fullScreen: {
              enable: false,
              zIndex: 1,
            },
            interactivity: {
              detectsOn: 'canvas',
              events: {
                onHover: {
                  enable: true,
                  mode: 'bubble',
                },
                resize: true,
              },
              modes: {
                bubble: {
                  distance: 40,
                  duration: 2,
                  opacity: 1.5,
                  size: 7,
                  speed: 3,
                },
              },
            },
            particles: {
              color: {
                value: ['#A8A9A9', '#EF1D1D', '#000000'],
                animation: {
                  enable: false,
                  speed: 20,
                  sync: false,
                },
              },
              lineLinked: {
                blink: false,
                color: 'random',
                consent: false,
                distance: 30,
                enable: false,
                opacity: 0.3,
                width: 0.5,
              },
              move: {
                attract: {
                  enable: false,
                  rotate: {
                    x: 600,
                    y: 1200,
                  },
                },
                bounce: false,
                direction: 'none',
                enable: true,
                outMode: 'bounce',
                random: true,
                speed: 0.5,
                straight: false,
              },
              number: {
                density: {
                  enable: false,
                  area: 2000,
                },
                limit: 0,
                value: 200,
              },
              opacity: {
                animation: {
                  enable: true,
                  minimumValue: 0.8,
                  speed: 1,
                  sync: false,
                },
                random: false,
                value: 1,
              },
              shape: {
                type: 'circle',
              },
              size: {
                animation: {
                  enable: false,
                  minimumValue: 0.1,
                  speed: 40,
                  sync: false,
                },
                random: true,
                value: 4,
              },
            },
            polygon: {
              draw: {
                enable: true,
                stroke: {
                  color: {
                    value: 'rgba(255,255,255,0.2)',
                  },
                  width: 0.5,
                  opacity: 0.2,
                },
              },
              enable: true,
              inline: {
                arrangement: 'one-per-point',
              },
              move: {
                radius: 10,
                type: 'path',
              },
              scale: 0.5,
              type: 'inline',
              url: humanImg,
            },
          }}
        />
      )}
    </div>
  )
}
