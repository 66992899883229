import React, { memo } from 'react'

// IMPORTS
import Particles from 'react-tsparticles'
import { loadFull } from 'tsparticles'

var particlesInitFlag = false
// var particlesLoadedFlag = false

const BubblesBackground = memo((props) => {
  const particlesInit = async (main) => {
    if (!particlesInitFlag) {
      props.setOpacity(0)
      particlesInitFlag = true
      await loadFull(main)
    }
  }
  const particlesLoaded = (container) => {
    // if (!particlesLoadedFlag) {
    //   particlesLoadedFlag = true

      setTimeout(() => {
        props.setOpacity(1)
      }, 1000)
    // }
  }

  const oldOptions = {
    fpsLimit: 70,
    background: {
      color: {
        value: '',
      },
      position: '50% 50%',
      repeat: 'no-repeat',
      size: 'cover',
    },
    fullScreen: {
      enable: false,
      zIndex: -2,
    },
    interactivity: {
      events: {
        onClick: {
          mode: 'push',
        },
        onHover: {
          enable: true,
          mode: 'repulse',
        },
      },
      modes: {
        bubble: {
          distance: 400,
          duration: 2,
          opacity: 0.8,
          size: 40,
          divs: {
            distance: 200,
            duration: 0.4,
            mix: false,
            selectors: [],
          },
        },
        grab: {
          distance: 400,
        },
        repulse: {
          distance: 100,
          divs: {
            distance: 200,
            duration: 0.4,
            factor: 100,
            speed: 1,
            maxSpeed: 50,
            easing: 'ease-out-quad',
            selectors: [],
          },
        },
      },
    },
    particles: {
      color: {
        value: '#ffffff',
      },
      links: {
        color: {
          value: '#ffffff',
        },
        distance: 150,
        opacity: 0.4,
      },
      move: {
        attract: {
          rotate: {
            x: 600,
            y: 1200,
          },
        },
        enable: true,
        path: {},
        outModes: {
          default: 'destroy',
          bottom: 'destroy',
          left: 'destroy',
          right: 'destroy',
          top: 'destroy',
        },
        speed: 2,
        spin: {},
      },
      number: {
        density: {
          enable: true,
        },
        value: 0,
      },
      opacity: {
        animation: {
          speed: 3,
          minimumValue: 0.1,
        },
      },
      size: {
        value: {
          min: 0.1,
          max: 7,
        },
        animation: {
          enable: true,
          sync: true,
          destroy: 'min',
          startValue: 'min',
          minimumValue: 0.1,
        },
      },
    },
    emitters: [
      {
        particles: {
          color: '#000000',
        },
        autoPlay: true,
        fill: true,
        life: {
          wait: false,
        },
        rate: {
          quantity: 0.1,
          delay: 0.1,
        },
        shape: 'square',
        startCount: 0,
        size: {
          mode: 'percent',
          height: 100,
          width: 100,
        },
        direction: 'left',
        position: {
          x: 100,
          y: 50,
        },
      },
      {
        particles: {
          color: '#EF1D1D',
        },
        autoPlay: true,
        fill: true,
        life: {
          wait: false,
        },
        rate: {
          quantity: 0.1,
          delay: 0.1,
        },
        shape: 'square',
        startCount: 0,
        size: {
          mode: 'percent',
          height: 100,
          width: 100,
        },
        direction: 'top',
        position: {
          x: 50,
          y: 100,
        },
      },
      {
        particles: {
          color: '#A8A9A9',
        },
        autoPlay: true,
        fill: true,
        life: {
          wait: false,
        },
        rate: {
          quantity: 0.1,
          delay: 0.1,
        },
        shape: 'square',
        startCount: 0,
        size: {
          mode: 'percent',
          height: 100,
          width: 100,
        },
        direction: 'right',
        position: {
          x: 0,
          y: 50,
        },
      },
    ],
  }

  return (
    <div
      className={`position-absolute top-0 start-0 w-100 h-100 back ${
        props.white ? 'main-canvas-bg-white' : 'main-canvas-bg'
      }`}
    >
      <div className="canvas-container h-100 d-lg-block d-none">
        <Particles
          className="h-100"
          id={props.id}
          init={particlesInit}
          loaded={particlesLoaded}
          options={oldOptions}
        />
      </div>
      <div className="canvas-container h-100 d-lg-none">
        <Particles
          className="h-100"
          id={props.id + 'mob'}
          init={props.particlesInit}
          options={{
            fpsLimit: 70,
            background: {
              color: {
                value: '',
              },
              position: '50% 50%',
              repeat: 'no-repeat',
              size: 'cover',
            },
            fullScreen: {
              enable: false,
              zIndex: -2,
            },
            interactivity: {
              events: {
                onClick: {
                  mode: 'push',
                },
                onHover: {
                  enable: true,
                  mode: 'repulse',
                },
              },
              modes: {
                bubble: {
                  distance: 400,
                  duration: 2,
                  opacity: 0.8,
                  size: 40,
                  divs: {
                    distance: 200,
                    duration: 0.4,
                    mix: false,
                    selectors: [],
                  },
                },
                grab: {
                  distance: 400,
                },
                repulse: {
                  distance: 100,
                  divs: {
                    distance: 200,
                    duration: 0.4,
                    factor: 100,
                    speed: 1,
                    maxSpeed: 50,
                    easing: 'ease-out-quad',
                    selectors: [],
                  },
                },
              },
            },
            particles: {
              color: {
                value: '#ffffff',
              },
              links: {
                color: {
                  value: '#ffffff',
                },
                distance: 150,
                opacity: 0.4,
              },
              move: {
                attract: {
                  rotate: {
                    x: 600,
                    y: 1200,
                  },
                },
                enable: true,
                path: {},
                outModes: {
                  default: 'destroy',
                  bottom: 'destroy',
                  left: 'destroy',
                  right: 'destroy',
                  top: 'destroy',
                },
                speed: 2,
                spin: {},
              },
              number: {
                density: {
                  enable: true,
                },
                value: 0,
              },
              opacity: {
                animation: {
                  speed: 3,
                  minimumValue: 0.1,
                },
              },
              size: {
                value: {
                  min: 0.1,
                  max: 8,
                },
                animation: {
                  enable: true,
                  sync: true,
                  destroy: 'min',
                  startValue: 'min',
                  minimumValue: 0.1,
                },
              },
            },
            emitters: [
              {
                particles: {
                  color: {
                    value: ['#A8A9A9', '#EF1D1D', '#000000'],
                  },
                },
                autoPlay: true,
                fill: true,
                life: {
                  wait: false,
                },
                rate: {
                  quantity: 1,
                  delay: 0.1,
                },
                shape: 'square',
                startCount: 0,
                size: {
                  mode: 'percent',
                  height: 100,
                  width: 100,
                },
                direction: 'top',
                position: {
                  x: 50,
                  y: 100,
                },
              },
            ],
          }}
        />
      </div>
    </div>
  )
})

export default BubblesBackground
