import React, { useState, useEffect } from 'react'

// SWIPER IMPORTS
import 'swiper/css'
import 'swiper/css/navigation'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Navigation } from 'swiper'

// CUSTOM COMPONENTS
import TitleDevider from './TitleDevider'
import ImageLightbox from './ImageLightbox'
import { Link } from 'react-router-dom'

const RelatedWork = (props) => {
  const [modalImageIndex, setModalImageIndex] = useState(-1)

  // ANIMATION INITIALIZATION
  useEffect(() => {
    window.triggerScroll()
  }, [])

  var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));


  return (
    <div id='tarrr' className="py-5 related-work position-relative overflow-hidden">
      <div className="py-lg-5">
        <div className="container py-5" animate="left">
          <div className="row">
            <TitleDevider
              title={props.title}
              title_white
              smallTitle={props.smallTitle}
            />
          </div>
          <div className="d-flex position-relative justify-content-center align-items-center home-sec1">
            <div className="slider-prev-button-rw me-4 flex-shrink-0"></div>
            <div className="col-xl-11 col-md-10 col-sm-9 col-6">
              <Swiper
                navigation={{
                  nextEl: '.slider-next-button-rw',
                  prevEl: '.slider-prev-button-rw',
                }}
                spaceBetween={20}
                slidesPerView={4}
                autoplay={{
                  delay: 10000,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                }}
                modules={[Navigation, Autoplay]}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    // spaceBetween: 20,
                  },
                  575.9: {
                    slidesPerView: 2,
                    // spaceBetween: 40,
                  },
                  991.9: {
                    slidesPerView: 2,
                    // spaceBetween: 40,
                  },
                  1199.9: {
                    slidesPerView: 3,
                    // spaceBetween: 50,
                  },
                }}
                className="rw-slider"
              >
                {props.workContent?.map((link, index) => (
                  <SwiperSlide key={index}>
                    {props.gallery ? (
                      <div
                        onClick={() => setModalImageIndex(index)}
                        className="related-work-border ratio ratio-1x1 overflow-hidden position-relative pointer"
                      >
                        <img src={link} alt="work" className="cover" />
                      </div>
                    ) : (
                      <Link to={"/our-work/" + link.slug}>
                        <div
                          onClick={() => setModalImageIndex(index)}
                          className="related-work-border ratio ratio-1x1 overflow-hidden position-relative pointer on-hover-overlay"
                        >
                          <img src={ process.env.REACT_APP_BASE_URL + (link.home_image ? link.home_image : link.image)} alt="work" className="cover" />
                          <div className='overlay'></div>
                        </div>
                        <div className="related-work-text">
                          <p className="text-center mb-0">{link.title}</p>
                        </div>
                      </Link>
                    )}
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div className="slider-next-button-rw ms-4 flex-shrink-0"></div>
          </div>
        </div>
      </div>

      <div
        className="position-absolute top-0 start-0 related-work-ratio back"
        style={{ backgroundImage: `url(${props.bgImage})`, backgroundAttachment: isSafari ? 'scroll' : 'fixed' }}
      ></div>


      {props.gallery && (
        <ImageLightbox
          image={props.workContent[modalImageIndex]}
          hideModal={() => setModalImageIndex(-1)}
          show={modalImageIndex !== -1}
          next={() => {
            modalImageIndex < props.workContent.length - 1
              ? setModalImageIndex(modalImageIndex + 1)
              : setModalImageIndex(0)
          }}
          prev={() => {
            modalImageIndex > 0
              ? setModalImageIndex(modalImageIndex - 1)
              : setModalImageIndex(props.workContent.length - 1)
          }}
        />
      )}
    </div>
  )
}

export default RelatedWork
