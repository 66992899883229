import React, { Fragment, useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

// IMAGES IMPORTS
import logo from "../assets/fixed-images/logo.svg";
import menuOpen from "../assets/fixed-images/menu-open.svg";
import menuClose from "../assets/fixed-images/menu-close.svg";

// DUMMY IMAGES

import GlobalState from "../contexts/GlobalState";

const Layout = (props) => {
  // ANIMATION INITIALIZATION
  useEffect(() => {
    window.triggerScroll();
  }, []);


  const { generalDataGlobal } = useContext(GlobalState);
  const newStr =   generalDataGlobal[0]?.footer_settings.copyright.replace("%%year%%",new Date().getFullYear());


  const [toggleMenu, setToggleMenu] = useState(false);

  // THIS FUNCTION OPENS AND CLOSES THE MENU
  const toggleMenuHandler = () => {
  //  let width = document.getElementById('get-margin').offsetWidth + document.getElementById('black-back').offsetWidth;
  //  let offset = $("#get-margin").offset();
  //  const offsetRight = window.innerWidth - offset.left;
  //   setToggleMenu(!toggleMenu);
  //   if(toggleMenu){
  //     document.getElementById("root").style.marginRight = "0";
  //   }else{
  //     document.getElementById("root").style.marginRight = (offsetRight - 10) + 'px';
  //   }

  setToggleMenu(!toggleMenu);
  };

  // PREVENT SCROLLING WHEN THE MENU IS OPEN
  useEffect(() => {
    if (toggleMenu === true) {
      document.getElementsByTagName("html")[0].style.overflow = "hidden";
    } else {
      document.getElementsByTagName("html")[0].style.overflowX = "hidden";
      document.getElementsByTagName("html")[0].style.overflowY = "unset";
    }
  }, [toggleMenu]);

  return (
    <Fragment>
      {/* NAVBAR */}
      <div className="nav-front position-relative">
        <div className="container">
          <nav className="d-flex justify-content-between align-items-end">
            <Link to="/">
              <div className="logo">
                <img src={logo} alt="logo" className="w-100" />
              </div>
            </Link>
            <div onClick={toggleMenuHandler} className="pointer menu-button">
              <img src={menuOpen} alt="menu" className="w-100" />
            </div>
          </nav>
        </div>
      </div>

      {/* MENU */}
      <div
        onClick={toggleMenuHandler}
        className={`menu ${toggleMenu ? "show" : ""}`}
      >
        <div className="position-relative">
          <div className="black-back" id="black-back"></div>
          <div className="container-md">
            <div className="row justify-content-end">
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="col-xxl-4 col-xl-5 col-lg-6 col-md-7 col-12 bg-black vh-100 d-flex px-0"
                id="get-margin"
              >
                <div className="menu-social d-flex flex-column justify-content-end">
                  {generalDataGlobal[0].social_media.map((link, index) => (
                    <a
                      key={index}
                      href={link.url}
                      rel="noreferrer noopener"
                      target="_blank"
                    >
                      <div className="ratio ratio-1x1 mt-2 social-icons-menu">
                        <img
                          src={process.env.REACT_APP_BASE_URL + link.white_icon}
                          alt="social"
                          className="contain"
                        />
                      </div>
                    </a>
                  ))}
                </div>
                <div className="d-flex flex-column w-100">
                  <div className="close-menu-button align-self-end me-md-0 me-3">
                    <div
                      onClick={toggleMenuHandler}
                      className="ratio ratio-1x1 pointer"
                    >
                      <img
                        src={menuClose}
                        alt="close menu"
                        className="contain"
                      />
                    </div>
                  </div>
                  <div className="menu-items h-100 d-flex flex-column justify-content-center">
                    <ul>
                      <li>
                        <Link
                          to="/"
                          className={
                            props.active === "Home" ? "active-nav-item" : ""
                          }
                        >
                          {generalDataGlobal[0].menu_items["home"]}
                        </Link>
                      </li>

                      <li>
                        <Link
                          to="/who-we-are"
                          className={
                            props.active === "WHO WE ARE"
                              ? "active-nav-item"
                              : ""
                          }
                        >
                          {generalDataGlobal[0].menu_items["who-we-are"]}
                        </Link>
                      </li>

                      <li>
                        <Link
                          to="/what-we-do"
                          className={
                            props.active === "WHAT WE DO"
                              ? "active-nav-item"
                              : ""
                          }
                        >
                          {generalDataGlobal[0].menu_items["what-we-do"]}
                        </Link>
                      </li>

                      <li>
                        <Link
                          to="/our-work"
                          className={
                            props.active === "OUR WORK" ? "active-nav-item" : ""
                          }
                        >
                          {generalDataGlobal[0].menu_items["our-work"]}
                        </Link>
                      </li>

                      <li>
                        <Link
                          to="/join-the-team"
                          className={
                            props.active === "JOIN THE TEAM"
                              ? "active-nav-item"
                              : ""
                          }
                        >
                          {generalDataGlobal[0].menu_items["join-the-team"]}
                        </Link>
                      </li>

                      <li>
                        <Link
                          to="/lets-talk"
                          className={
                            props.active === "LET’S TALK"
                              ? "active-nav-item"
                              : ""
                          }
                        >
                          {generalDataGlobal[0].menu_items["lets-talk"]}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* CONTENT */}
      {props.children}

      {/* FOOTER */}
      {!props.contact && !props.joinTheTeam && (
        <div className="position-relative">
          <div className="container d-lg-block d-none">
            <div className="row">
              <div
                className="col-lg-6 footer-sec1-p1 d-flex flex-column justify-content-center position-relative px-lg-0 px-5"
                animate="left"
              >
                <div className="text-footer-sec1-1 text-break text-uppercase mb-0"  dangerouslySetInnerHTML={{
                  __html: generalDataGlobal[0].footer_settings.left_section_title
                }}>
                </div>
                <Link to="/lets-talk">
                  <button className="red-button w-fit text-uppercase mt-4">
                  {generalDataGlobal[0].footer_settings.left_button_text}

                  </button>
                </Link>
                <div className="d-lg-none d-block position-absolute top-0 start-0 w-100 h-100 back">
                  <div className="ratio ratio-bg-footer1 h-100">
                    <img src={process.env.REACT_APP_BASE_URL + generalDataGlobal[0].footer_settings.left_section_bg} alt="background" className="cover" />
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 footer-sec1-p2 d-flex flex-column justify-content-center position-relative"
                animate="right"
              >
                <div className="text-footer-sec1-2 text-uppercase text-break mb-0" dangerouslySetInnerHTML={{
                  __html: generalDataGlobal[0].footer_settings.right_section_title
                }}>
                </div>
                <Link to="/join-the-team">
                  <button className="black-button text-uppercase mt-4">
                    {generalDataGlobal[0].footer_settings.right_button_text}
                  </button>
                </Link>
                <div className="d-lg-none d-block position-absolute top-0 start-0 w-100 h-100 back">
                  <div className="ratio ratio-bg-footer2 h-100"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="position-absolute top-0 start-0 w-100 h-100 back d-lg-block d-none">
            <div className="d-flex flex-lg-row flex-column h-100">
              <div className="col-lg-6 px-0">
                <div className="ratio ratio-bg-footer1 h-100">
                  <img src={process.env.REACT_APP_BASE_URL + generalDataGlobal[0].footer_settings.left_section_bg} alt="" className="cover" />
                </div>
              </div>
              <div className="col-lg-6 px-0">
                <div className="ratio ratio-bg-footer2 h-100">
                  <img src={process.env.REACT_APP_BASE_URL + generalDataGlobal[0].footer_settings.right_section_bg} alt="" className="cover" />
                </div>
              </div>
            </div>
          </div>

          {/* FOOTER ON MOBILE */}
          <div className="position-relative d-lg-none">
            <div className="container">
              <div className="row">
                <div
                  className="col-12 footer-sec1-p1 d-flex flex-column justify-content-center"
                  animate="left"
                >
                  <div className="text-footer-sec1-1 text-break text-uppercase mb-0" dangerouslySetInnerHTML={{
                    __html: generalDataGlobal[0].footer_settings.left_section_title
                  }}>
                  </div>
                  <Link to="/lets-talk">
                    <button className="red-button w-fit text-uppercase mt-4">
                    {generalDataGlobal[0].footer_settings.left_button_text}
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="d-lg-none d-block position-absolute top-0 start-0 w-100 h-100 back">
              <div className="ratio ratio-bg-footer1 h-100">
                <img src={process.env.REACT_APP_BASE_URL + generalDataGlobal[0].footer_settings.left_section_bg} alt="" className="cover" />
              </div>
            </div>
          </div>

          <div className="position-relative d-lg-none overlay-home-section">
            <div className="container">
              <div className="row">
                <div
                  className="col-lg-6 footer-sec1-p2 d-flex flex-column justify-content-center position-relative"
                  animate="right"
                >
                  <div className="text-footer-sec1-2 text-uppercase text-break mb-0" dangerouslySetInnerHTML={{
                    __html: generalDataGlobal[0].footer_settings.right_section_title
                  }}>
                  </div>
                  <Link to="/join-the-team">
                    <button className="black-button text-uppercase mt-4">
                      {generalDataGlobal[0].footer_settings.right_button_text}
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="d-lg-none d-block position-absolute top-0 start-0 w-100 h-100 back">
              <div className="ratio ratio-bg-footer2 h-100">
                <img src={  process.env.REACT_APP_BASE_URL + generalDataGlobal[0].footer_settings.right_section_mobile} alt="" className="cover" />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* CONTACT PAGE */}
      {props.contact && (
        <div className="position-relative">
          <div className="container">
            <div className="row py-5">
              <div
                className="col d-flex flex-column justify-content-center position-relative py-5"
                animate="left"
              >
                <div className="text-footer-sec1-2 text-uppercase text-break mb-0 text-footer-contact" dangerouslySetInnerHTML={{
                  __html: generalDataGlobal[0].footer_settings.right_section_title
                }}>
                </div>
                <Link to="/join-the-team">
                  <button className="black-button text-uppercase mt-4">
                    {generalDataGlobal[0].footer_settings.right_button_text}
                  </button>
                </Link>
              </div>
            </div>
            <div className=" d-block position-absolute top-0 start-0 w-100 h-100 back">
              <div className="ratio ratio-bg-footer2 h-100">
                <img
                  src={process.env.REACT_APP_BASE_URL + generalDataGlobal[0].footer_settings.right_section_bg}
                  alt="background"
                  className="cover d-lg-none"
                />
                <img
                  src={process.env.REACT_APP_BASE_URL + generalDataGlobal[0].footer_settings.right_section_bg}
                  alt="background"
                  className="cover d-lg-block d-none"
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* JOIN THE TEAM */}
      {props.joinTheTeam && (
        <div className="position-relative">
          <div className="container">
            <div className="row py-5">
              <div
                className="col d-flex flex-column justify-content-center position-relative py-5"
                animate="left"
              >
                <div className="text-footer-sec1-2 text-uppercase text-break mb-0 text-footer-contact" dangerouslySetInnerHTML={{
                  __html: generalDataGlobal[0].footer_settings.left_section_title
                  }}>
                </div>
                <Link to="/lets-talk">
                  <button className="red-button text-uppercase mt-4">
                    {generalDataGlobal[0].footer_settings.left_button_text}
                  </button>
                </Link>
              </div>
            </div>
            <div className=" d-block position-absolute top-0 start-0 w-100 h-100 back">
              <div className="ratio ratio-bg-footer1 h-100">
                <img src={process.env.REACT_APP_BASE_URL + generalDataGlobal[0].footer_settings.left_section_bg} alt="" className="cover" />
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="container footer-sec2">
        <div className="row justify-content-between">
          <div
            className="col-md-6 d-flex justify-content-md-start justify-content-center  mt-md-0 mt-5 order-md-0 order-2"
            animate="down"
          >
            <div className="footer-i-img">
              <img src={process.env.REACT_APP_BASE_URL + generalDataGlobal[0].footer_settings.menu_items_image} alt="i" />
            </div>
            <div className="ms-4 d-flex flex-column footer-menu-items">
              

              <Link
                to="/who-we-are"
                className={
                  props.active === "WHO WE ARE" ? "active-nav-item" : ""
                }
              >
                {generalDataGlobal[0].menu_items["who-we-are"]}
              </Link>

              <Link
                to="/what-we-do"
                className={
                  props.active === "WHAT WE DO" ? "active-nav-item" : ""
                }
              >
                {generalDataGlobal[0].menu_items["what-we-do"]}
              </Link>

              <Link
                to="/our-work"
                className={props.active === "OUR WORK" ? "active-nav-item" : ""}
              >
                {generalDataGlobal[0].menu_items["our-work"]}
              </Link>

              <Link
                to="/join-the-team"
                className={
                  props.active === "JOIN THE TEAM" ? "active-nav-item" : ""
                }
              >
                {generalDataGlobal[0].menu_items["join-the-team"]}
              </Link>

              <Link
                to="/lets-talk"
                className={
                  props.active === "LET’S TALK" ? "active-nav-item" : ""
                }
              >
                {generalDataGlobal[0].menu_items["lets-talk"]}
              </Link>
            </div>
          </div>
          <div className="col-md-5 text-lg-end text-center" animate="down">
            <div className="footer-follow-text" dangerouslySetInnerHTML={{
              __html: generalDataGlobal[0].footer_settings.follow_text
            }}>
              
            </div>
            <div className="d-flex justify-content-lg-end justify-content-center flex-wrap">
              {generalDataGlobal[0].social_media.map((link, index) => (
                <a
                  key={index}
                  href={link.url}
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  <div className="mt-2 ms-3 social-icons social-icons-footer">
                    <img src={process.env.REACT_APP_BASE_URL + link.icon} alt="social" className="contain svg" />
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className="row">
          <p className="copyright-text mb-0 text-lg-start text-center">
            {newStr}
          </p>
        </div>
      </div>
    </Fragment>
  );
};

export default Layout;
