import React, { useEffect } from 'react'

// COMPONENTS IMPORTS
import TitleDevider from '../components/TitleDevider'
import BubblesBackgroundWrapper from './BubblesBackgroundWrapper'

const BubbleBanner = (props) => {
  // ANIMATION INITIALIZATION
  useEffect(() => {
    window.triggerScroll()
  }, [])

  return (
    <div
      className={`position-relative ${props.text ? "wwd-banner" : "wwd-banner-pb"}  `}
    >
      <div className="container front position-relative">
        <div className={`row ${props.text ? 'wwd-banner-title-m' : ''}`}>
          <div className="col-12" animate="">
            <TitleDevider title={props.title} center={true}></TitleDevider>
          </div>
        </div>
        {
          props.subtitle ? 
          <div className='banner-subtitle-bubbles d-flex justify-content-center'>
            <div className='col-md-10 col-12'>
              <p className='wwa-banner-text text-center mb-0'>
                {props.subtitle}
              </p>
            </div>
          </div> : null
        }
        {props.text ? (
          <div className="row justify-content-sm-end justify-content-center wwd-banner-bubble-m front">
            <div
              className="col-lg-4 col-md-6 col-sm-7 wwd-banner-bubble-w me-md-4 px-xl-4"
              animate="down"
            >
              <div className="wwd-banner-bubble ratio ratio-1x1">
                <div className="d-flex align-items-center justify-content-center overflow-hidden">
                  <p className="text-center m-sm-5 px-5 px-sm-0 h-fit wwd-banner-bubble-text mb-0 text-break">
                    {props.text}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-1"></div>
          </div>
        ) : null}
      </div>
      <BubblesBackgroundWrapper whithHuman={false} id={props.id} />
    </div>
  )
}

export default BubbleBanner
