import React, { Fragment, useState, useEffect, useContext } from "react";

// IMPORTS
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";

// COMPONENTS IMPORTS
import ImageBanner from "../components/ImageBanner";
import Layout from "../components/Layout";
import DateInput from "../components/DateInput";
import CountryCode from "../components/CountryCode";

// ANIMATION IMPORTS
import AOS from "aos";
import "aos/dist/aos.css";

// IMAGES IMPORTS
import nextButton from "../assets/fixed-images/next-button.svg";
import disabledButton from "../assets/fixed-images/arrow-disabled.svg";
import attachment from "../assets/fixed-images/attach.svg";

import GlobalState from "../contexts/GlobalState";
import Api from "../components/api";
import BubbleBanner from "../components/BubbleBanner";
import { Helmet } from "react-helmet";

const JoinTheTeam = () => {
  const { generalDataGlobal } = useContext(GlobalState);
  const [dobPlaceHolder, setDobPlaceHolder] = useState();
  const [visaExpiryPlaceHolder, setVisaExpiryPlaceHolder] = useState();
  const [issueDatePlaceHolder, setIssueDatePlaceHolder] = useState();
  const [passportExpiryPlaceHolder, setPassportExpiryPlaceHolder] = useState();
  const [errorMessages, setErrorMessages] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [visaExpiryDate, setVisaExpiryDate] = useState("");
  const [issueDate, setIssueDate] = useState("");
  const [passportExpiryDate, setPassportExpiryDate] = useState("");
  const [passportCopy, setPassportCopy] = useState();
  const [cv, setCv] = useState();
  const [visaCopy, setVisaCopy] = useState();
  const [passportSizedPhoto, setPassportSizedPhoto] = useState();
  const [covid19VaccineCertificate, setCovid19VaccineCertificate] = useState();
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    if (generalDataGlobal) setLoader(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const formSubmitHandler = (e) => {
    e.preventDefault();
    setLoading(true);

    let fd = new FormData();
    fd.append("passport_copy", passportCopy);
    fd.append("cv_copy", cv);
    fd.append("visa_copy", visaCopy);
    fd.append("passport_photo", passportSizedPhoto);
    fd.append("vaccine_certificate", covid19VaccineCertificate);
    fd.append("phone", phoneNumber);

    Object.keys(formData).forEach((formDataKey) => {
      if (Array.isArray(formData[formDataKey])) {
        formData[formDataKey].forEach((obj, index) => {
          Object.keys(formData[formDataKey][index]).forEach((subkey) => {
            fd.append(
              formDataKey + "[" + index + "][" + subkey + "]",
              formData[formDataKey][index][subkey]
            );
          });
        });
      } else {
        fd.append(formDataKey, formData[formDataKey]);
      }
    });

    setApiOptions({
      url: "/join-the-team",
      method: "post",
      data: fd,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      callback: (r) => {
        setLoading(false);
        setFormData({
          first_name: "",
          last_name: "",
          gender_id: "",
          DOB: "",
          email: "",
          nationality_id: "",
          residence_id: "",
          visa_expiry: "",
          passport_number: "",
          issue_date: "",
          issue_place_id: "",
          passport_expiry: "",
          covid_vaccine_id: "",
          number_doses: "",
          current_profession: "",
          interest_id: "",
          division_id: "",
          position_id: "",
          level_id: "",
        });
        setDateOfBirth();
        setPhoneNumber("");
        setVisaExpiryDate();
        setIssueDate();
        setPassportExpiryDate();
        setPassportCopy();
        setCv();
        setVisaCopy();
        setPassportSizedPhoto();
        setCovid19VaccineCertificate();
        setErrorMessages();
        setSuccessMessage("your application has been submitted");
        setTimeout(() => {
          setSuccessMessage("");
        }, 3000);
      },
      catch422: (errors) => {
        setLoading(false);
        setSuccessMessage();
        setErrorMessages(errors);
      },
    });
  };

  const formSubmitHandlerPage2 = (e) => {
    e.preventDefault();
    setLoading(true);

    let fd = new FormData();
    fd.append("phone", phoneNumber);

    Object.keys(formData).forEach((formDataKey) => {
      if (Array.isArray(formData[formDataKey])) {
        formData[formDataKey].forEach((obj, index) => {
          Object.keys(formData[formDataKey][index]).forEach((subkey) => {
            fd.append(
              formDataKey + "[" + index + "][" + subkey + "]",
              formData[formDataKey][index][subkey]
            );
          });
        });
      } else {
        fd.append(formDataKey, formData[formDataKey]);
      }
    });

    setApiOptions({
      url: "/join-the-team-2",
      method: "post",
      data: fd,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      callback: (r) => {
        setLoading(false);
        setErrorMessages();
        setActivePage(3);
      },
      catch422: (errors) => {
        setLoading(false);
        setSuccessMessage();
        setErrorMessages(errors);
      },
    });
  };
 
  const formSubmitHandlerPage1 = (e) => {
    e.preventDefault();
    setLoading(true);

    let fd = new FormData();
    fd.append("phone", phoneNumber);

    Object.keys(formData).forEach((formDataKey) => {
      if (Array.isArray(formData[formDataKey])) {
        formData[formDataKey].forEach((obj, index) => {
          Object.keys(formData[formDataKey][index]).forEach((subkey) => {
            fd.append(
              formDataKey + "[" + index + "][" + subkey + "]",
              formData[formDataKey][index][subkey]
            );
          });
        });
      } else {
        fd.append(formDataKey, formData[formDataKey]);
      }
    });

    setApiOptions({
      url: "/join-the-team-1",
      method: "post",
      data: fd,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      callback: (r) => {
        setLoading(false);
        setErrorMessages();
        setActivePage(2);
      },
      catch422: (errors) => {
        setLoading(false);
        setSuccessMessage();
        setErrorMessages(errors);
      },
    });
  };


  const formatDate = (date) => {
    return `${date.getFullYear()}-${
      date.getMonth() < 9
        ? "0" + Number(Number(date.getMonth()) + 1)
        : Number(date.getMonth()) + 1
    }-${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}`;
  };

  const genders = () => {
    const genders = generalDataGlobal[0]?.gender?.map((singleRestriction) => ({
      value: singleRestriction.id,
      label: singleRestriction.title,
    }));
    return genders;
  };

  const countries = () => {
    const countries = generalDataGlobal[0]?.countries?.map(
      (singleRestriction) => ({
        value: singleRestriction.id,
        label: singleRestriction.title,
      })
    );
    return countries;
  };

  const vaccines = () => {
    const vaccines = generalDataGlobal[0]?.vaccines?.map(
      (singleRestriction) => ({
        value: singleRestriction.id,
        label: singleRestriction.title,
      })
    );
    return vaccines;
  };

  const interest = () => {
    const interest = generalDataGlobal[0]?.interest?.map(
      (singleRestriction) => ({
        value: singleRestriction.id,
        label: singleRestriction.title,
      })
    );
    return interest;
  };

  // const divisions = () => {
  //   const division = generalDataGlobal[0]?.division?.map(
  //     (singleRestriction) => ({
  //       value: singleRestriction.id,
  //       label: singleRestriction.title,
  //     })
  //   );
  //   return division;
  // };

  const positions = () => {
    const position = generalDataGlobal[0]?.position?.map(
      (singleRestriction) => ({
        value: singleRestriction.id,
        label: singleRestriction.title,
      })
    );
    return position;
  };

  const levels = () => {
    const level = generalDataGlobal[0]?.level?.map((singleRestriction) => ({
      value: singleRestriction.id,
      label: singleRestriction.title,
    }));
    return level;
  };

  // ANIMATION INITIALIZATION
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", AOS.refresh());
      window.addEventListener("load", AOS.refreshHard);
    }
  }, []);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const [activePage, setActivePage] = useState(1);
  const prevButtonHandler = () => {
    if (activePage === 1) {
      return;
    }
    setActivePage(activePage - 1);
  };

  // STYLING THE SELECT TAGS
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#000" : "#656565",
      backgroundColor: state.isSelected ? "#f5f5f5" : "#fff",
      borderBottom: "1px dotted #d0d0d0",
      padding: 10,
      fontFamily: "RobotoThin",
      transition: "all 0.3s ease-in-out",
      "&:hover": {
        backgroundColor: "#ef1d1d",
        color: "white",
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      fontFamily: "RobotoThin",
      // fontSize: '16px',
      padding: "0",
    }),
    input: (provided) => ({
      ...provided,
      fontFamily: "RobotoThin",
      fontSize: "16px",
      padding: "0",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: 20,
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      borderRadius: 20,
      padding: 0,
      margin: "0",
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: 0,
      margin: "0",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  function getIndex(value, arr, prop) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i][prop] === value) {
        return i;
      }
    }
    return -1; //to handle the case where the value doesn't exist
  }

  useEffect(() => {
    if (errorMessages) {
      if (
        errorMessages.email ||
        errorMessages.first_name ||
        errorMessages.last_name
      ) {
        setActivePage(1);
      } else if (errorMessages.current_profession) {
        setActivePage(2);
      }
    }
  }, [errorMessages]);

  const [apiOptions, setApiOptions] = useState();

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    gender_id: "",
    DOB: "",
    email: "",
    nationality_id: "",
    residence_id: "",
    visa_expiry: "",
    passport_number: "",
    issue_date: "",
    issue_place_id: "",
    passport_expiry: "",
    covid_vaccine_id: "",
    number_doses: "",
    current_profession: "",
    interest_id: "",
    division_id: "",
    position_id: "",
    level_id: "",
  });

  const inputProps = (key, index = null, subKey = null) => {
    return {
      value:
        index === null && subKey === null
          ? formData[key]
          : formData[key][index][subKey],
      onChange: (e) => changeInput(e.target.value, key, index, subKey),
    };
  };

  const changeInput = (newValue, key, index = null, subKey = null) => {
    let newFormData = { ...formData };
    if (index === null && subKey === null) newFormData[key] = newValue;
    else newFormData[key][index][subKey] = newValue;
    setFormData(newFormData);
  };

  return (
    <>
      <Api options={apiOptions} />

      {loader ? (
        <div className="custom-loader middle-page"></div>
      ) : (
        <Layout active="JOIN THE TEAM" joinTheTeam={true}>
          <Helmet>
            <title>{generalDataGlobal[0]?.seo_pages[4].title}</title>
            <meta
              name="title"
              content={generalDataGlobal[0]?.seo_pages[4].title}
            />
            <meta
              name="description"
              content={generalDataGlobal[0]?.seo_pages[4].description}
            />
            <meta
              property="og:title"
              content={generalDataGlobal[0]?.seo_pages[4].title}
            />
            <meta
              property="og:description"
              content={generalDataGlobal[0]?.seo_pages[4].description}
            />
            <meta
              property="og:image"
              content={generalDataGlobal[0]?.seo_pages[4].image}
            />
            <meta
              property="twitter:title"
              content={generalDataGlobal[0]?.seo_pages[4].title}
            />
            <meta
              property="twitter:description"
              content={generalDataGlobal[0]?.seo_pages[4].description}
            />
            <meta
              property="twitter:image"
              content={generalDataGlobal[0]?.seo_pages[4].image}
            />
          </Helmet>
          {generalDataGlobal[0].join_the_team_settings.banner_image != null ? (
            <ImageBanner
              title={generalDataGlobal[0].join_the_team_settings.banner_title}
              text={generalDataGlobal[0].join_the_team_settings.banner_subtitle}

              image={
                process.env.REACT_APP_BASE_URL +
                generalDataGlobal[0].join_the_team_settings.banner_image
              }
            />
          ) : (
            <BubbleBanner
              title={generalDataGlobal[0].join_the_team_settings.banner_title}
              subtitle={generalDataGlobal[0].join_the_team_settings.banner_subtitle}

              id="wwa"
            />
          )}

          <div className="container py-5 mb-5">
            <div className="row justify-content-center">
              <div className="col-sm-8 col-12 d-flex justify-content-between">
                <div
                  onClick={() => setActivePage(1)}
                  className={`jtt-page-number pointer ${
                    activePage === 1 || activePage === 2 || activePage === 3
                      ? "jtt-page-number-red"
                      : ""
                  }`}
                >
                  1
                </div>
                <div
                  onClick={formSubmitHandlerPage1}
                  className={`jtt-page-number pointer ${
                    activePage === 2 || activePage === 3
                      ? "jtt-page-number-red"
                      : ""
                  }`}
                >
                  2
                </div>
                <div
                  onClick={formSubmitHandlerPage2}
                  className={`jtt-page-number pointer ${
                    activePage === 3 ? "jtt-page-number-red" : ""
                  }`}
                >
                  3
                </div>
              </div>
            </div>
            <div className="row align-items-center justify-content-center">
              <div className="col-sm-10 col-12 py-5">
                <form onSubmit={formSubmitHandler} className="jtt-form">
                  {activePage === 1 && (
                    <div>
                      <div
                        data-aos="fade-in"
                        data-aos-duration="1500"
                        className="row jtt-form-title mx-1"
                      >
                        <h3 className="text-capitalize">
                          {
                            generalDataGlobal[0]?.join_the_team_settings
                              .page1_title
                          }
                        </h3>
                      </div>
                      <div className="position-relative">
                        <div
                          onClick={prevButtonHandler}
                          className="jtt-navigation-abs pointer d-sm-block d-none"
                        >
                          {activePage !== 1 ? (
                            <img
                              src={nextButton}
                              alt="arrow-right"
                              className="flip contain mt-4 w-100"
                            />
                          ) : (
                            <img
                              src={disabledButton}
                              alt="arrow-right"
                              className="flip contain w-100"
                            />
                          )}
                        </div>
                        <div
                          onClick={activePage === 1 ?  formSubmitHandlerPage1 : (activePage === 2 ? formSubmitHandlerPage2 : null) }
                          className="jtt-navigation-abs-right pointer d-sm-block d-none"
                        >
                          {activePage !== 3 ? (
                            <img
                              src={nextButton}
                              alt="arrow-right"
                              className="contain w-100"
                            />
                          ) : (
                            <img
                              src={disabledButton}
                              alt="arrow-right"
                              className="contain w-100"
                            />
                          )}
                        </div>
                        <div
                          data-aos="fade-in"
                          data-aos-duration="1500"
                          className="row mt-4 gx-3"
                        >
                          <div className="col-lg-6 mt-lg-0 mt-2">
                            <input
                              type="text"
                              placeholder={
                                generalDataGlobal[0]?.join_the_team_settings
                                  .first_name_placeholder
                              }
                              id="first-name"
                              name="first_name"
                              {...inputProps("first_name")}
                              className={
                                errorMessages
                                  ? errorMessages["first_name"]
                                    ? "error-border"
                                    : ""
                                  : ""
                              }
                            />
                            {errorMessages ? (
                              <p className="text-danger mb-0 error-text mt-2">
                                {errorMessages["first_name"]}
                              </p>
                            ) : null}
                          </div>
                          <div className="col-lg-6 mt-lg-0 mt-2">
                            <input
                              type="text"
                              placeholder={
                                generalDataGlobal[0]?.join_the_team_settings
                                  .last_name_placeholder
                              }
                              id="last-name"
                              name="last_name"
                              {...inputProps("last_name")}
                              className={
                                errorMessages
                                  ? errorMessages["last_name"]
                                    ? "error-border"
                                    : ""
                                  : ""
                              }
                            />
                            {errorMessages ? (
                              <p className="text-danger mb-0 error-text mt-2">
                                {errorMessages["last_name"]}
                              </p>
                            ) : null}
                          </div>
                        </div>
                        <div
                          data-aos="fade-in"
                          data-aos-duration="1500"
                          className="row align-items-top pt-lg-2 gx-3"
                        >
                          <div className={"col-lg-6 mt-lg-0 mt-2 "}>
                            <Select
                              classNamePrefix="form-select"
                              options={genders()}
                              styles={customStyles}
                              placeholder={
                                generalDataGlobal[0]?.join_the_team_settings
                                  .gender_placeholder
                              }
                              value={
                                genders()[
                                  getIndex(
                                    formData["gender_id"] !== ""
                                      ? Number(Number(formData["gender_id"]))
                                      : "",
                                    genders(),
                                    "value"
                                  )
                                ]
                              }
                              onChange={(e) => {
                                changeInput(e.value, "gender_id", null, null);
                              }}
                            />
                            {errorMessages ? (
                              <p className="text-danger mb-0 error-text mt-2">
                                {errorMessages["gender_id"]}
                              </p>
                            ) : null}
                          </div>
                          <div
                            className={
                              "col-lg-6 mt-lg-0 mt-2 position-relative"
                            }
                          >
                            <DateInput
                              placeholder={
                                formData["DOB"]
                                  ? dobPlaceHolder
                                  : generalDataGlobal[0]?.join_the_team_settings
                                      .dob_placeholder
                              }
                              state={dateOfBirth}
                              setState={setDateOfBirth}
                              onChange={(e) => {
                                changeInput(formatDate(e), "DOB", null, null);
                                setDobPlaceHolder(formatDate(e));
                              }}
                            />
                            {errorMessages ? (
                              <p className="text-danger mb-0 error-text mt-2">
                                {errorMessages["DOB"]}
                              </p>
                            ) : null}
                          </div>
                        </div>
                        <div
                          data-aos="fade-in"
                          data-aos-duration="1500"
                          className="row align-items-top mt-lg-2 gx-3"
                        >
                          <div className="col-lg-6 mt-lg-0 mt-2">
                            <input
                              type="email"
                              placeholder={
                                generalDataGlobal[0]?.join_the_team_settings
                                  .email_placeholder
                              }
                              id="full-name"
                              name="email"
                              {...inputProps("email")}
                              className={
                                errorMessages
                                  ? errorMessages["email"]
                                    ? "error-border"
                                    : ""
                                  : ""
                              }
                            />
                            {errorMessages ? (
                              <p className="text-danger mb-0 error-text mt-2">
                                {errorMessages["email"]}
                              </p>
                            ) : null}
                          </div>
                          <div className="col-lg-6 mt-lg-0 mt-2">
                            <CountryCode
                              placeholder={
                                generalDataGlobal[0]?.join_the_team_settings
                                  .phone_placeholder
                              }
                              state={phoneNumber}
                              setState={setPhoneNumber}
                              onChange={(e) => setPhoneNumber(e)}
                            />
                            {errorMessages ? (
                              <p className="text-danger mb-0 error-text mt-2">
                                {errorMessages["phone"]}
                              </p>
                            ) : null}
                          </div>
                        </div>
                        <div
                          data-aos="fade-in"
                          data-aos-duration="1500"
                          className="row align-items-top mt-lg-2 gx-3"
                        >
                          <div className="col-lg-6 mt-lg-0 mt-2">
                            <Select
                              classNamePrefix="form-select"
                              options={countries()}
                              styles={customStyles}
                              placeholder={
                                generalDataGlobal[0]?.join_the_team_settings
                                  .nationality_placeholder
                              }
                              value={
                                countries()[
                                  getIndex(
                                    formData["nationality_id"] !== ""
                                      ? Number(
                                          Number(formData["nationality_id"])
                                        )
                                      : "",
                                    countries(),
                                    "value"
                                  )
                                ]
                              }
                              onChange={(e) => {
                                changeInput(
                                  e.value,
                                  "nationality_id",
                                  null,
                                  null
                                );
                              }}
                            />
                            {errorMessages ? (
                              <p className="text-danger mb-0 error-text mt-2">
                                {errorMessages["nationality_id"]}
                              </p>
                            ) : null}
                          </div>
                          <div className="col-lg-3 mt-lg-0 mt-2">
                            <Select
                              classNamePrefix="form-select"
                              options={countries()}
                              styles={customStyles}
                              placeholder={
                                generalDataGlobal[0]?.join_the_team_settings
                                  .residence_placeholder
                              }
                              value={
                                countries()[
                                  getIndex(
                                    formData["residence_id"] !== ""
                                      ? Number(Number(formData["residence_id"]))
                                      : "",
                                    countries(),
                                    "value"
                                  )
                                ]
                              }
                              onChange={(e) => {
                                changeInput(
                                  e.value,
                                  "residence_id",
                                  null,
                                  null
                                );
                              }}
                            />
                            {errorMessages ? (
                              <p className="text-danger mb-0 error-text mt-2">
                                {errorMessages["residence_id"]}
                              </p>
                            ) : null}
                          </div>
                          <div className="col-lg-3 mt-lg-0 mt-2 position-relative">
                            <DateInput
                              placeholder={
                                formData["visa_expiry"]
                                  ? visaExpiryPlaceHolder
                                  : generalDataGlobal[0]?.join_the_team_settings
                                      .visa_expiry_placeholder
                              }
                              maxYear='1'
                              state={visaExpiryDate}
                              setState={setVisaExpiryDate}
                              onChange={(e) => {
                                changeInput(
                                  formatDate(e),
                                  "visa_expiry",
                                  null,
                                  null
                                );
                                setVisaExpiryPlaceHolder(formatDate(e));
                              }}
                            />
                            {errorMessages ? (
                              <p className="text-danger mb-0 error-text mt-2">
                                {errorMessages["visa_expiry"]}
                              </p>
                            ) : null}
                          </div>
                        </div>
                        <div
                          data-aos="fade-in"
                          data-aos-duration="1500"
                          className="row align-items-top mt-lg-2 gx-3 px-0"
                        >
                          <div className="col-lg-3 mt-lg-0 mt-2">
                            <input
                              type="text"
                              placeholder={
                                generalDataGlobal[0]?.join_the_team_settings
                                  .passport_nb_placeholder
                              }
                              id="passport-number"
                              name="passport_number"
                              {...inputProps("passport_number")}
                            />
                            {errorMessages ? (
                              <p className="text-danger mb-0 error-text mt-2">
                                {errorMessages["passport_number"]}
                              </p>
                            ) : null}
                          </div>
                          <div className="col-lg-3 mt-lg-0 mt-2 position-relative">
                            <DateInput
                              placeholder={
                                formData["issue_date"]
                                  ? issueDatePlaceHolder
                                  : generalDataGlobal[0]?.join_the_team_settings
                                      .issue_date_placeholder
                              }
                              state={issueDate}
                              setState={setIssueDate}
                              onChange={(e) => {
                                changeInput(
                                  formatDate(e),
                                  "issue_date",
                                  null,
                                  null
                                );
                                setIssueDatePlaceHolder(formatDate(e));
                              }}
                            />
                            {errorMessages ? (
                              <p className="text-danger mb-0 error-text mt-2">
                                {errorMessages["issue_date"]}
                              </p>
                            ) : null}
                          </div>
                          <div className="col-lg-3 mt-lg-0 mt-2">
                            <Select
                              classNamePrefix="form-select"
                              options={countries()}
                              styles={customStyles}
                              placeholder={
                                generalDataGlobal[0]?.join_the_team_settings
                                  .issue_place_placeholder
                              }
                              value={
                                countries()[
                                  getIndex(
                                    formData["issue_place_id"] !== ""
                                      ? Number(
                                          Number(formData["issue_place_id"])
                                        )
                                      : "",
                                    countries(),
                                    "value"
                                  )
                                ]
                              }
                              onChange={(e) => {
                                changeInput(
                                  e.value,
                                  "issue_place_id",
                                  null,
                                  null
                                );
                              }}
                            />
                            {errorMessages ? (
                              <p className="text-danger mb-0 error-text mt-2">
                                {errorMessages["issue_place_id"]}
                              </p>
                            ) : null}
                          </div>
                          <div className="col-lg-3 mt-lg-0 mt-2 position-relative">
                            <DateInput
                              placeholder={
                                formData["passport_expiry"]
                                  ? passportExpiryPlaceHolder
                                  : generalDataGlobal[0]?.join_the_team_settings
                                      .passport_expiry_placeholder
                              }
                              maxYear='1'
                              state={passportExpiryDate}
                              setState={setPassportExpiryDate}
                              onChange={(e) => {
                                changeInput(
                                  formatDate(e),
                                  "passport_expiry",
                                  null,
                                  null
                                );
                                setPassportExpiryPlaceHolder(formatDate(e));
                              }}
                            />
                            {errorMessages ? (
                              <p className="text-danger mb-0 error-text mt-2">
                                {errorMessages["passport_expiry"]}
                              </p>
                            ) : null}
                          </div>
                        </div>
                        <div
                          data-aos="fade-in"
                          data-aos-duration="1500"
                          className="row align-items-top mt-lg-2 gx-3"
                        >
                          <div className="col-lg-6 mt-lg-0 mt-2">
                            <Select
                              classNamePrefix="form-select"
                              options={vaccines()}
                              styles={customStyles}
                              placeholder={
                                generalDataGlobal[0]?.join_the_team_settings
                                  .vaccine_placeholder
                              }
                              value={
                                vaccines()[
                                  getIndex(
                                    formData["covid_vaccine_id"]
                                      ? Number(
                                          Number(formData["covid_vaccine_id"])
                                        )
                                      : "",
                                    vaccines(),
                                    "value"
                                  )
                                ]
                              }
                              onChange={(e) => {
                                changeInput(
                                  e.value,
                                  "covid_vaccine_id",
                                  null,
                                  null
                                );
                              }}
                            />
                            {errorMessages ? (
                              <p className="text-danger mb-0 error-text mt-2">
                                {errorMessages["covid_vaccine_id"]}
                              </p>
                            ) : null}
                          </div>
                          <div className="col-lg-6 mt-lg-0 mt-2">
                            <input
                              type="number"
                              placeholder={
                                generalDataGlobal[0]?.join_the_team_settings
                                  .number_doses_placeholder
                              }
                              id="number-of-doses"
                              name="number_doses"
                              {...inputProps("number_doses")}
                            />
                            {errorMessages ? (
                              <p className="text-danger mb-0 error-text mt-2">
                                {errorMessages["number_doses"]}
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      {/* {noNavigationMessage ? (
                        <p className="text-red">{noNavigationMessage}</p>
                      ) : null} */}
                    </div>
                  )}
                  {activePage === 2 && (
                    <Fragment>
                      <div
                        data-aos="fade-in"
                        data-aos-duration="1500"
                        className="row jtt-form-title mx-1"
                      >
                        <h3 className="text-capitalize">
                          {
                            generalDataGlobal[0]?.join_the_team_settings
                              .page2_title
                          }
                        </h3>
                      </div>
                      <div className="position-relative">
                        <div
                          onClick={prevButtonHandler}
                          className="jtt-navigation-abs pointer d-sm-block d-none"
                        >
                          {activePage !== 1 ? (
                            <img
                              src={nextButton}
                              alt="arrow-right"
                              className="flip cover w-100"
                            />
                          ) : (
                            <img
                              src={disabledButton}
                              alt="arrow-right"
                              className="flip contain w-100"
                            />
                          )}
                        </div>
                        <div
                          onClick={activePage === 1 ?  formSubmitHandlerPage1 : (activePage === 2 ? formSubmitHandlerPage2 : null) }
                          className="jtt-navigation-abs-right pointer d-sm-block d-none"
                        >
                          {activePage !== 3 ? (
                            <img
                              src={nextButton}
                              alt="arrow-right"
                              className="contain w-100"
                            />
                          ) : (
                            <img
                              src={disabledButton}
                              alt="arrow-right"
                              className="contain w-100"
                            />
                          )}
                        </div>
                        <div
                          data-aos="fade-in"
                          data-aos-duration="1500"
                          className="row mt-4 gx-3 justify-content-center"
                        >
                          <div className="col-xl-5 col-lg-6 col-md-8 mt-lg-0 mt-2">
                            <input
                              type="text"
                              placeholder={
                                generalDataGlobal[0]?.join_the_team_settings
                                  .current_profession_placeholder
                              }
                              id="current-profession"
                              name="current_profession"
                              {...inputProps("current_profession")}
                            />
                            {errorMessages ? (
                              <p className="text-danger mb-0 error-text mt-2">
                                {errorMessages["current_profession"]}
                              </p>
                            ) : null}
                          </div>
                        </div>
                        <div
                          data-aos="fade-in"
                          data-aos-duration="1500"
                          className="row mt-lg-2 gx-3 justify-content-center"
                        >
                          <div className="col-xl-5 col-lg-6 col-md-8 mt-lg-0 mt-2">
                            <Select
                              classNamePrefix="form-select"
                              options={interest()}
                              styles={customStyles}
                              placeholder={
                                generalDataGlobal[0]?.join_the_team_settings
                                  .interest_palceholder
                              }
                              value={
                                interest()[
                                  getIndex(
                                    formData["interest_id"]
                                      ? Number(Number(formData["interest_id"]))
                                      : "",
                                    interest(),
                                    "value"
                                  )
                                ]
                              }
                              onChange={(e) => {
                                changeInput(e.value, "interest_id", null, null);
                              }}
                            />
                            {errorMessages ? (
                              <p className="text-danger mb-0 error-text mt-2">
                                {errorMessages["interest_id"]}
                              </p>
                            ) : null}
                          </div>
                        </div>
                        <div
                          data-aos="fade-in"
                          data-aos-duration="1500"
                          className="row mt-lg-2 gx-3 justify-content-center"
                        >
                          <div className="col-xl-5 col-lg-6 col-md-8 mt-lg-0 mt-2">
                            <Select
                              classNamePrefix="form-select"
                              options={positions()}
                              styles={customStyles}
                              placeholder={
                                generalDataGlobal[0]?.join_the_team_settings
                                  .position_placeholder
                              }
                              value={
                                positions()[
                                  getIndex(
                                    formData["position_id"]
                                      ? Number(Number(formData["position_id"]))
                                      : "",
                                    positions(),
                                    "value"
                                  )
                                ]
                              }
                              onChange={(e) => {
                                changeInput(e.value, "position_id", null, null);
                              }}
                            />
                            {errorMessages ? (
                              <p className="text-danger mb-0 error-text mt-2">
                                {errorMessages["position_id"]}
                              </p>
                            ) : null}
                          </div>
                        </div>
                        <div
                          data-aos="fade-in"
                          data-aos-duration="1500"
                          className="row mt-lg-2 gx-3 justify-content-center"
                        >
                          <div className="col-xl-5 col-lg-6 col-md-8 mt-lg-0 mt-2">
                            <Select
                              classNamePrefix="form-select"
                              options={levels()}
                              styles={customStyles}
                              placeholder={
                                generalDataGlobal[0]?.join_the_team_settings
                                  .level_placeholder
                              }
                              value={
                                levels()[
                                  getIndex(
                                    formData["level_id"]
                                      ? Number(Number(formData["level_id"]))
                                      : "",
                                    levels(),
                                    "value"
                                  )
                                ]
                              }
                              onChange={(e) => {
                                changeInput(e.value, "level_id", null, null);
                              }}
                            />
                            {errorMessages ? (
                              <p className="text-danger mb-0 error-text mt-2">
                                {errorMessages["level_id"]}
                              </p>
                            ) : null}
                          </div>
                        </div>
                        {/* {noNavigationMessage2 ? (
                          <p className="text-center text-red">{noNavigationMessage2}</p>
                        ) : null} */}
                      </div>
                    </Fragment>
                  )}
                  {activePage === 3 && (
                    <Fragment>
                      <div
                        data-aos="fade-in"
                        data-aos-duration="1500"
                        className="row jtt-form-title mx-1"
                      >
                        <h3 className="text-capitalize">
                          {
                            generalDataGlobal[0]?.join_the_team_settings
                              .page3_title
                          }
                        </h3>
                      </div>
                      <div className="row mt-4 justify-content-between">
                        <div className="col-lg-6 mt-lg-0 mt-2 position-relative">
                          <div
                            onClick={prevButtonHandler}
                            className="jtt-navigation-abs-3 pointer d-sm-block d-none"
                          >
                            {activePage !== 1 ? (
                              <img
                                src={nextButton}
                                alt="arrow-right"
                                className="flip contain w-100"
                              />
                            ) : (
                              <img
                                src={disabledButton}
                                alt="arrow-right"
                                className="flip contain w-100"
                              />
                            )}
                          </div>
                          <div
                          onClick={activePage === 1 ?  formSubmitHandlerPage1 : (activePage === 2 ? formSubmitHandlerPage2 : null) }
                            className="jtt-navigation-abs-right-3 pointer d-lg-none d-sm-block d-none"
                          >
                            {activePage !== 3 ? (
                              <img
                                src={nextButton}
                                alt="arrow-right"
                                className="contain w-100"
                              />
                            ) : (
                              <img
                                src={disabledButton}
                                alt="arrow-right"
                                className="contain w-100"
                              />
                            )}
                          </div>

                          <label
                            data-aos="fade-in"
                            data-aos-duration="1500"
                            htmlFor="cv"
                            className="custom-file-upload d-flex align-items-center justify-content-between ps-4 text-capitalize"
                          >
                             {cv?.name
                              ? cv?.name
                              : 'CV Copy'}
                            <div className="upload-button d-flex justify-content-between align-items-center flex-shrink-0">
                              <p className="text-capitalize mb-0">
                                {
                                  generalDataGlobal[0]?.join_the_team_settings
                                    .upload_text
                                }
                              </p>
                              <img
                                src={attachment}
                                alt="upload"
                                width="16"
                                height="auto"
                                className="ms-3"
                              />
                            </div>
                          </label>
                          <input
                            id="cv"
                            type="file"
                            name="cv"
                            onChange={(e) => setCv(e.target.files[0])}
                          />

                          {errorMessages ? (
                            <p className="text-danger mb-0 error-text mt-2">
                              {errorMessages["cv_copy"]}
                            </p>
                          ) : null}


                          <label
                            data-aos="fade-in"
                            data-aos-duration="1500"
                            htmlFor="passport"
                            className="custom-file-upload d-flex align-items-center justify-content-between ps-4 text-capitalize mt-2"
                          >
                            {passportCopy?.name
                              ? passportCopy?.name
                              : generalDataGlobal[0]?.join_the_team_settings
                                  .passport_copy_placeholder}
                            <div className="upload-button d-flex justify-content-between align-items-center flex-shrink-0">
                              <p className="text-capitalize mb-0">
                                {
                                  generalDataGlobal[0]?.join_the_team_settings
                                    .upload_text
                                }
                              </p>
                              <img
                                src={attachment}
                                alt="upload"
                                width="16"
                                height="auto"
                                className="ms-3"
                              />
                            </div>
                          </label>
                          <input
                            id="passport"
                            type="file"
                            name="passport_copy"
                            onChange={(e) => setPassportCopy(e.target.files[0])}
                          />

                          {errorMessages ? (
                            <p className="text-danger mb-0 error-text mt-2">
                              {errorMessages["passport_copy"]}
                            </p>
                          ) : null}

                          <label
                            data-aos="fade-in"
                            data-aos-duration="1500"
                            htmlFor="visa"
                            className="custom-file-upload d-flex align-items-center justify-content-between ps-4 mt-2 text-capitalize"
                          >
                            {visaCopy?.name
                              ? visaCopy?.name
                              : generalDataGlobal[0]?.join_the_team_settings
                                  .visa_copy_placeholder}
                            <div className="upload-button d-flex justify-content-between align-items-center flex-shrink-0">
                              <p className="text-capitalize mb-0">
                                {
                                  generalDataGlobal[0]?.join_the_team_settings
                                    .upload_text
                                }
                              </p>
                              <img
                                src={attachment}
                                alt="upload"
                                width="16"
                                height="auto"
                                className="ms-3"
                              />
                            </div>
                          </label>
                          <input
                            id="visa"
                            type="file"
                            name="visa_copy"
                            onChange={(e) => setVisaCopy(e.target.files[0])}
                          />

                          {errorMessages ? (
                            <p className="text-danger mb-0 error-text mt-2">
                              {errorMessages["visa_copy"]}
                            </p>
                          ) : null}

                          <label
                            data-aos="fade-in"
                            data-aos-duration="1500"
                            htmlFor="passport-photo"
                            className="custom-file-upload d-flex align-items-center justify-content-between ps-4 mt-2 text-capitalize"
                          >
                            {passportSizedPhoto?.name
                              ? passportSizedPhoto?.name
                              : generalDataGlobal[0]?.join_the_team_settings
                                  .passport_photo_placeholder}

                            <div className="upload-button d-flex justify-content-between align-items-center flex-shrink-0">
                              <p className="text-capitalize mb-0">
                                {
                                  generalDataGlobal[0]?.join_the_team_settings
                                    .upload_text
                                }
                              </p>
                              <img
                                src={attachment}
                                alt="upload"
                                width="16"
                                height="auto"
                                className="ms-3"
                              />
                            </div>
                          </label>
                          <input
                            id="passport-photo"
                            type="file"
                            name="passport_photo"
                            onChange={(e) =>
                              changeInput(
                                setPassportSizedPhoto(e.target.files[0])
                              )
                            }
                          />

                          {errorMessages ? (
                            <p className="text-danger mb-0 error-text mt-2">
                              {errorMessages["passport_photo"]}
                            </p>
                          ) : null}

                          <label
                            data-aos="fade-in"
                            data-aos-duration="1500"
                            htmlFor="covid-19"
                            className="custom-file-upload d-flex align-items-center justify-content-between ps-4 mt-2 text-capitalize"
                          >
                            {covid19VaccineCertificate?.name
                              ? covid19VaccineCertificate?.name
                              : generalDataGlobal[0]?.join_the_team_settings
                                  .vaccine_certificate_placeholder}

                            <div className="upload-button d-flex justify-content-between align-items-center flex-shrink-0">
                              <p className="text-capitalize mb-0">
                                {
                                  generalDataGlobal[0]?.join_the_team_settings
                                    .upload_text
                                }
                              </p>
                              <img
                                src={attachment}
                                alt="upload"
                                width="16"
                                height="auto"
                                className="ms-3"
                              />
                            </div>
                          </label>
                          <input
                            id="covid-19"
                            type="file"
                            name="vaccine_certificate"
                            onChange={(e) =>
                              setCovid19VaccineCertificate(e.target.files[0])
                            }
                          />
                          {errorMessages ? (
                            <p className="text-danger mb-0 error-text mt-2">
                              {errorMessages["vaccine_certificate"]}
                            </p>
                          ) : null}
                        </div>
                        <div className="col-lg-6 mt-lg-0 mt-4 ps-lg-55 d-flex flex-column position-relative">
                          <div
                          
                          onClick={activePage === 1 ?  formSubmitHandlerPage1 : (activePage === 2 ? formSubmitHandlerPage2 : null) }
                            className="jtt-navigation-abs-right-3 pointer d-lg-block d-none"
                          >
                            {activePage !== 3 ? (
                              <img
                                src={nextButton}
                                alt="arrow-right"
                                className="contain w-100"
                              />
                            ) : (
                              <img
                                src={disabledButton}
                                alt="arrow-right"
                                className="contain w-100"
                              />
                            )}
                          </div>
                          <div
                            className="form-important-text"
                            data-aos="fade-in"
                            data-aos-duration="1500"
                          >
                            <p className="text-custom-red mb-2">
                              {
                                generalDataGlobal[0]?.join_the_team_settings
                                  .form_note_title
                              }
                            </p>
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  generalDataGlobal[0]?.join_the_team_settings
                                    .form_note_description,
                              }}
                            ></div>
                          </div>

                          <div className="d-flex justify-content-end align-items-center flex-grow-1 align-self-end">
                            <button
                              className="red-button-border w-fit text-uppercase mt-lg-auto mt-4"
                              type="submit"
                              disabled={loading}
                            >
                              {
                                generalDataGlobal[0]?.join_the_team_settings
                                  .submit_button
                              }
                            </button>
                          </div>
                        </div>
                        {successMessage ? <p>{successMessage}</p> : null}
                      </div>
                      {loading ? <div className="custom-loader"></div> : null}
                    </Fragment>
                  )}
                </form>
              </div>
            </div>
            <div className="row justify-content-between d-sm-none">
              <div className="col-2">
                <div
                  onClick={prevButtonHandler}
                  className="jtt-navigation-mob pointer"
                >
                  {activePage !== 1 ? (
                    <img
                      src={nextButton}
                      alt="arrow-right"
                      className="flip contain"
                    />
                  ) : (
                    <img
                      src={disabledButton}
                      alt="arrow-right"
                      className="flip contain"
                    />
                  )}
                </div>
              </div>
              <div className="col-2">
                <div
                
                onClick={activePage === 1 ?  formSubmitHandlerPage1 : (activePage === 2 ? formSubmitHandlerPage2 : null) }
                  className="jtt-navigation-mob pointer ms-auto d-flex justify-content-end"
                >
                  {activePage !== 3 ? (
                    <img
                      src={nextButton}
                      alt="arrow-right"
                      className="contain"
                    />
                  ) : (
                    <img
                      src={disabledButton}
                      alt="arrow-right"
                      className="contain"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </Layout>
      )}
    </>
  );
};

export default JoinTheTeam;
