import "bootstrap/dist/css/bootstrap.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// PAGES IMPORTS
import Home from "./pages/Home";
import WhoWeAre from "./pages/WhoWeAre";
import WhatWeDo from "./pages/WhatWeDo";
import OurWork from "./pages/OurWork";
import JoinTheTeam from "./pages/JoinTheTeam";
import Contact from "./pages/Contact";
import WhatWeDoSinglePage from "./pages/WhatWeDoSinglePage";
import OurWorkSingle from "./pages/OurWorkSingle";
import ScrollToTop from "./components/ScrollToTop";
import axios from "axios";
import { useEffect, useState } from "react";
import GlobalState from "./contexts/GlobalState";
import Api from "./components/api";

 // "homepage": "/p/classact/public"


function App() {
  // const axiosBaseUrl = "https://stagingsrv.intouchmena.com/p/classact/public/api";
  const axiosBaseUrl = "https://classactad.com/api";
  // const axiosBaseUrl = "http://127.0.0.1:8000/api";

  axios.defaults.baseURL = axiosBaseUrl;
  const [generalData, setGeneralData] = useState();
  const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});

  useEffect(() => {
    setGeneralDataApiOptions({
      url: "general",
      callback: (res) => {
        setGeneralData(res.data);
      },
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Api options={generalDataApiOptions} />
      {!generalData ? null : (
        <GlobalState.Provider
          value={{
            generalDataGlobal: [generalData, setGeneralData],
          }}
        >
          <BrowserRouter basename={process.env.PUBLIC_URL}>
            <ScrollToTop>
              <Routes>
                <Route path="*" element ={<Home />} />
                <Route index element={<Home />} />
                <Route path="who-we-are" element={<WhoWeAre />} />
                <Route exact path="what-we-do" element={<WhatWeDo />} />
                <Route
                  exact
                  path="what-we-do/:slug"
                  element={<WhatWeDoSinglePage />}
                />
                <Route path="our-work" element={<OurWork />} />
                <Route path="our-work/:slug" element={<OurWorkSingle />} />
                <Route path="join-the-team" element={<JoinTheTeam />} />
                <Route path="lets-talk" element={<Contact />} />
              </Routes>
            </ScrollToTop>
          </BrowserRouter>
        </GlobalState.Provider>
      )}
    </>
  );
}

export default App;
