import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

// COMPONENTS IMPORTS
import BubbleBanner from "../components/BubbleBanner";
import Layout from "../components/Layout";
import WorkCard from "../components/WorkCard";

//DUMMY IMAGES

import GlobalState from "../contexts/GlobalState";
import ImageBanner from "../components/ImageBanner";
import Api from "../components/api";
import { Helmet } from "react-helmet";

const OurWork = () => {
  // ANIMATION INITIALIZATION

  const { generalDataGlobal } = useContext(GlobalState);
  const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
  const [projectsData, setProjectsData] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState();
  const [loader, setLoader] = useState(false);
  const [loadMore, setLoadMore] = useState(false);

  useEffect(() => {
    window.triggerScroll();
  }, []);

  useEffect(() => {
    if (page === 1) setLoader(true);
    if (page !== 1) setLoadMore(true);
    setGeneralDataApiOptions({
      url: "projects?page=" + page,
      callback: (res) => {
        let data = [...projectsData, ...res.data.data];
        setLastPage(res.data.last_page);
        setProjectsData(data);
        setLoader(false);
        setLoadMore(false);
      },
    });
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.triggerScroll();
  }, [projectsData]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Api options={generalDataApiOptions} />
      {loader ? (
        <div className="custom-loader middle-page"></div>
      ) : (
        <Layout active="OUR WORK">
          <Helmet>
            <title>{generalDataGlobal[0]?.seo_pages[3].title}</title>
            <meta
              name="title"
              content={generalDataGlobal[0]?.seo_pages[3].title}
            />
            <meta
              name="description"
              content={generalDataGlobal[0]?.seo_pages[3].description}
            />
            <meta
              property="og:title"
              content={generalDataGlobal[0]?.seo_pages[3].title}
            />
            <meta
              property="og:description"
              content={generalDataGlobal[0]?.seo_pages[3].description}
            />
            <meta
              property="og:image"
              content={generalDataGlobal[0]?.seo_pages[3].image}
            />
            <meta
              property="twitter:title"
              content={generalDataGlobal[0]?.seo_pages[3].title}
            />
            <meta
              property="twitter:description"
              content={generalDataGlobal[0]?.seo_pages[3].description}
            />
            <meta
              property="twitter:image"
              content={generalDataGlobal[0]?.seo_pages[3].image}
            />
          </Helmet>
          {generalDataGlobal[0].project_settings.image != null ? (
            <ImageBanner
              title={generalDataGlobal[0].project_settings.banner_title}
              text={generalDataGlobal[0].project_settings.banner_subtitle}
              image={
                process.env.REACT_APP_BASE_URL +
                generalDataGlobal[0].project_settings.image
              }
            />
          ) : (
            <BubbleBanner
              title={generalDataGlobal[0].project_settings.banner_title}
              subtitle={generalDataGlobal[0].project_settings.banner_subtitle}
              id="projects"
            />
          )}

          <div className="container py-4 my-2">
            <div className="row justify-content-center">
              {projectsData?.map((project, index) => (
                <div
                  key={index}
                  className={`col-sm-10 ${
                    index + 1 !== projectsData.length ? "mb-3" : ""
                  }`}
                  animate="left"
                >
                  <Link to={project.slug} className="text-decoration-none">
                    <WorkCard
                      title={project.title}
                      text={project.excerpt}
                      image={process.env.REACT_APP_BASE_URL + project.image}
                    />
                  </Link>
                </div>
              ))}
            </div>
            {loadMore ? (
              <div className="d-flex justify-content-center mt-1">
                <div className="custom-loader"></div>
              </div>
            ) : page !== lastPage ? (
              <div className="row justify-content-center" animate="">
                <button
                  className="red-button-border w-fit text-uppercase mt-3"
                  onClick={() => {
                    setPage(page + 1);
                    setLoadMore(true);
                  }}
                >
                  {generalDataGlobal[0].project_settings.load_more}
                </button>
              </div>
            ) : null}
          </div>
        </Layout>
      )}
    </>
  );
};

export default OurWork;
