import React, { useState, useEffect } from 'react'

// IMAGES IMPORTS
import nextButton from '../assets/fixed-images/next-button.svg'
import menuClose from '../assets/fixed-images/menu-close.svg'

const ImageLightbox = (props) => {
  const [show, setShow] = useState(false)
  useEffect(() => {
    if (props.show === true) {
      setTimeout(setShow(true), 500)
    }
  }, [props.show])

  const hideHandler = () => {
    setShow(false)
    setTimeout(props.hideModal, 500)
  }

  useEffect(() => {
    if (show === true) {
      document.getElementsByTagName('html')[0].style.overflow = 'hidden'
    } else {
      document.getElementsByTagName('html')[0].style.overflowX = 'hidden'
      document.getElementsByTagName('html')[0].style.overflowY = 'unset'
    }
  }, [show])

  return (
    <div
      onClick={hideHandler}
      className={`position-fixed top-0 start-0 h-100 w-100 image-modal d-flex align-items-center front ${
        show && 'image-modal-show'
      }`}
    >
      <div className="container">
        <div className="row justify-content-center align-items-center p-1">
          <div className="col-1 px-0">
            {props.prev && (
              <div
                onClick={(e) => {
                  e.stopPropagation()
                }}
                className="ms-auto ratio pointer front d-flex modal-arrow"
              >
                <img
                  onClick={props.prev}
                  src={nextButton}
                  alt="arrow"
                  className="flip"
                />
              </div>
            )}
          </div>
          <div className="col-lg-7 col-md-10 col-10 position-relative">
            <div
              onClick={hideHandler}
              className="ms-auto mb-lg-3 w-fit front pointer modal-close position-absolute"
            >
              <img src={menuClose} alt="close" />
            </div>

            <div className="ratio ratio-4x3">
              <img
                onClick={(e) => {
                  e.stopPropagation()
                }}
                src={props.image}
                alt=""
                className="cover"
              />
            </div>
          </div>
          <div className="col-1 px-0">
            {props.next && (
              <div
                onClick={(e) => {
                  e.stopPropagation()
                }}
                className="front modal-arrow d-flex pointer ratio"
              >
                <img
                  onClick={props.next}
                  src={nextButton}
                  alt="arrow"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ImageLightbox
