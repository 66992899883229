import React from 'react'

const WhatWeDoCard = (props) => {
    return (
        <div className={'wwdcard what-we-do ratio ratio-1x1 position-relative' + (props.index % 2 ? " gradient-color" : " red-color")}>
            <div className='d-flex flex-column align-items-center justify-content-center wwd-card-initial-content'>
                <div className='wwdcard-img ratio ratio-1x1'>
                    <img src={props.image} alt="icon" className="contain" />
                </div>
                <div className='wwdcard-text'>
                    <p className='text-capitalize text-center px-lg-5 px-4 mb-0'>{props.text_1}</p>
                </div>
            </div>
            <div className='position-absolute top-50 start-0 translate-middle-y wwdcard-add-text black-hover px-3 d-flex align-items-center justify-content-center'>
                <p className='text-center my-auto text-break'>{props.text_2}</p>
            </div>
        </div>
    )
}

export default WhatWeDoCard