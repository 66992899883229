import React, { useEffect, useState } from 'react'

// COMPONENTS IMPORTS
import TitleDevider from '../components/TitleDevider'

const ImageBanner = (props) => {
  // ANIMATION INITIALIZATION
  useEffect(() => {
    window.triggerScroll()
  }, [])

  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true)
    }, 100)

    return () => {
      setLoaded(false)
    }
  }, [])

  return (
    <div className={"position-relative wwa-banner" + (props.bubble_text ? " remove-pad" : "")}>
      <div className="container">
        <div
          className="row justify-content-center"
          animate=""
        >
          <div className="col-12">
            <TitleDevider title={props.title} center={true} />
          </div>
          {props.text ? (
            <div className="col-md-10 col-12">
              <p className="wwa-banner-text text-center mb-0">{props.text}</p>
            </div>
          ) : null}
        </div>
        {props.bubble_text ? (
          <div className="row justify-content-sm-end justify-content-center wwd-banner-bubble-m front add-margin-top">
            <div
              className="col-lg-4 col-md-6 col-sm-7 wwd-banner-bubble-w me-md-4 px-xl-4"
              animate="down"
            >
              <div className="wwd-banner-bubble ratio ratio-1x1">
                <div className="d-flex align-items-center justify-content-center overflow-hidden">
                  <p className="text-center m-sm-5 px-5 px-sm-0 h-fit wwd-banner-bubble-text mb-0 text-break">
                    {props.bubble_text}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-1"></div>
          </div>
        ) : null}
      </div>
      <div className="wwa-banner-bg overflow-hidden">
        <div className="ratio wwa-banner-bg-ratio">
          <img
            src={ props.image}
            alt="background"
            className={`cover hide-img ${loaded && 'show-img'}`}
          />
        </div>
      </div>
      
    </div>
  )
}

export default ImageBanner
