import React, { useContext, useEffect, useRef, useState } from "react";

// SWIPER IMPORTS
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// COMPONENTS IMPORTS
import Layout from "../components/Layout";
import BubbleBanner from "../components/BubbleBanner";
import RelatedWork from "../components/RelatedWork";

import { useNavigate, useParams } from "react-router-dom";
import GlobalState from "../contexts/GlobalState";
import Api from "../components/api";
import ImageBanner from "../components/ImageBanner";
import { Helmet } from "react-helmet";

export default function WhatWeDoSinglePage(props) {
  const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
  const [generalDataApiOptions1, setGeneralDataApiOptions1] = useState({});
  const [services, setServices] = useState();
  const [singlePageData, setSinglePageData] = useState();
  const { generalDataGlobal } = useContext(GlobalState);
  const [nextButton, setNextButton] = useState(true);
  const [prevButton, setPrevButton] = useState(true);
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();
  const params = useParams();
  const sectionRef = useRef(null);

  // ANIMATION INITIALIZATION
  useEffect(() => {
    window.triggerScroll();
    sectionRef.current.scrollIntoView();
  }, [services]);

  function getIndex(value, arr, prop) {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i][prop] === value) {
        return i;
      }
    }
    return -1; //to handle the case where the value doesn't exist
  }

  const handleNext = () => {
    setPrevButton(true);
    let index = getIndex(params.slug, services, "slug");

    if (index < services?.length - 1) navigate("/what-we-do/" + services[index+1].slug);

    if ((index + 1) === services?.length - 1) setNextButton(false);
  };

  const handlePrev = () => {
    setNextButton(true);
    let index = getIndex(params.slug, services, "slug");
    if (index > 0) navigate("/what-we-do/" + services[index - 1].slug);

    if (index === 1) setPrevButton(false);
  };

  useEffect(() => {
    setLoader(true);
    setGeneralDataApiOptions({
      url: "services/" + params.slug,
      callback: (res) => {
        setSinglePageData(res.data);
        setLoader(false);
      if (res.data.last_element) setNextButton(false);
      if(res.data.first_element) setPrevButton(false);
      },
    });
  }, [params.slug]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setLoader(true);
    setGeneralDataApiOptions1({
      url: "services",
      callback: (res) => {
        setServices(res.data.services);
        setLoader(false);
      },
    });
  }, [params.slug]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Api options={generalDataApiOptions} />
      <Api options={generalDataApiOptions1} />

      {loader ? (
        <div className="custom-loader middle-page"></div>
      ) : (
        <Layout active="WHAT WE DO">
          <Helmet>
            <title>{singlePageData?.single_service.title}</title>
            <meta
              name="title"
              content={singlePageData?.single_service.title}
            />
            <meta
              name="description"
              content={generalDataGlobal[0]?.seo_pages[2].description}
            />
            <meta
              property="og:title"
              content={singlePageData?.single_service.title}
            />
            <meta
              property="og:description"
              content={generalDataGlobal[0]?.seo_pages[2].description}
            />
            <meta
              property="og:image"
              content={generalDataGlobal[0]?.seo_pages[2].image}
            />
            <meta
              property="twitter:title"
              content={singlePageData?.single_service.title}
            />
            <meta
              property="twitter:description"
              content={generalDataGlobal[0]?.seo_pages[2].description}
            />
            <meta
              property="twitter:image"
              content={generalDataGlobal[0]?.seo_pages[2].image}
            />
          </Helmet>
          {generalDataGlobal[0].services_settings.banner_image != null ? (
            <ImageBanner
              title={generalDataGlobal[0].services_settings.banner_title}
              text={
                generalDataGlobal[0].services_settings.banner_excerpt
              }
              image={
                process.env.REACT_APP_BASE_URL +
                generalDataGlobal[0].services_settings.banner_image
              }
            />
          ) : (
            <BubbleBanner
              title={generalDataGlobal[0].services_settings.banner_title}
              subtitle={generalDataGlobal[0].services_settings.banner_excerpt}
              id="wwwds"
            />
          )}
          <div id="down" ref={sectionRef} className="container py-5">
            <div className="row justify-content-between align-items-center py-5 px-sm-0 px-2">
              <div className="col-sm-1 col-2 px-0 d-sm-block d-none">
                <div
                  className={
                    "slider-prev-button flex-shrink-0" +
                    (prevButton ? "" : " swiper-button-disabled")
                  }
                  onClick={() => handlePrev()}
                ></div>
              </div>
              <div className="col-sm-8 position-relative" animate="left">
                <div className="position-absolute top-0 start-0 w-100 wwds-arrow-mob">
                  <div className="row justify-content-center align-items-center">
                    <div className="col-sm-1 col-2 px-0 d-sm-none">
                      <div
                        className={
                          "slider-prev-button flex-shrink-0" +
                          (prevButton ? "" : " swiper-button-disabled")
                        }
                        onClick={() => handlePrev()}
                      ></div>
                    </div>
                    <div className="col-5">
                      <div className="ratio ratio-1x1 wwds-red-img"></div>
                    </div>
                    <div className="col-sm-1 col-2 px-0 d-sm-none">
                      <div
                        className={
                          "slider-next-button-rw flex-shrink-0 ms-auto" +
                          (nextButton ? "" : " swiper-button-disabled")
                        }
                        onClick={() => handleNext()}
                      ></div>
                    </div>
                  </div>
                </div>

                {services?.length > 0 ? (
                  <div className="row justify-content-center align-items-center">
                    <div className="col-lg-2 col-md-3 col-sm-4 col-5 pb-3">
                      <div className="ratio ratio-1x1 wwds-red-img mx-auto">
                        <img
                          src={
                            process.env.REACT_APP_BASE_URL +
                            singlePageData?.single_service?.icon
                          }
                          alt="category"
                          className="contain"
                        />
                      </div>
                    </div>
                    <div className="col-12 pb-3">
                      <h3 className="text-center text-capitalize wwds-sec1-title">
                        {singlePageData?.single_service?.title}
                      </h3>
                    </div>
                    <div className="col-12">
                      <div
                        className="wwds-sec1-text"
                        dangerouslySetInnerHTML={{
                          __html: singlePageData?.single_service?.description,
                        }}
                      ></div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="col-sm-1 col-2 px-0 d-sm-block d-none">
                <div
                  className={
                    "slider-next-button flex-shrink-0 ms-auto" +
                    (nextButton ? "" : " swiper-button-disabled")
                  }
                  onClick={() => handleNext()}
                ></div>
              </div>
            </div>
          </div>
          {singlePageData?.single_service?.service_projects.length > 0 ? (
            <RelatedWork
              title="related projects"
              bgImage={
                process.env.REACT_APP_BASE_URL +
                "" +
                generalDataGlobal[0].services_settings.related_projects_image
              }
              workContent={singlePageData?.single_service?.service_projects}
              smallTitle={true}
            />
          ) : null}
        </Layout>
      )}
    </>
  );
}
