import React, { useState } from 'react'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { getMonth, getYear } from 'date-fns'
import range from 'lodash/range'

// IMAGES IMPORTS
import formCalendar from '../assets/fixed-images/form-calendar.svg'

const DateInput = (props) => {
  const [datePickerOpen, setDatePickerOpen] = useState(false)

  const years = range(getYear(new Date())-120, props.maxYear ?  getYear(new Date())+20 : getYear(new Date()) + 1, 1)

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  return (
    <div>
      <DatePicker
        calendarClassName="form-calendar"
        className="form-date-picker pointer"
        readOnly={window.innerWidth > 992 ? false : true}
        onInputClick={() => setDatePickerOpen(!datePickerOpen)}
        onClickOutside={() => setDatePickerOpen(!datePickerOpen)}
        onSelect={() => setDatePickerOpen(false)}
        open={datePickerOpen ? true : false}
        placeholderText={props.placeholder}
        selected={props.state}
        onChange={props.onChange}
        calendarStartDay={1}
        onKeyDown={(e) => {
          e.preventDefault()
        }}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              margin: 10,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <button
              className="me-auto calendar-button"
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
            >
              {'<'}
            </button>
            <select
              className="calendar-select mx-auto pointer"
              value={months[getMonth(date)]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              className="calendar-select mx-auto pointer"
              value={getYear(date)}
              onChange={({ target: { value } }) => changeYear(value)}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <button
              className="calendar-button ms-auto"
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
            >
              {'>'}
            </button>
          </div>
        )}
      />
      <div className="calendar-icon">
        <img src={formCalendar} alt="calendar" />
      </div>
    </div>
  )
}

export default DateInput
