import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

//COMPONENTS IMPORTS
import ImageBanner from "../components/ImageBanner";
import Layout from "../components/Layout";
import RelatedWork from "../components/RelatedWork";
import WorkCard from "../components/WorkCard";

import GlobalState from "../contexts/GlobalState";
import Api from "../components/api";
import { Helmet } from "react-helmet";

const OurWorkSingle = () => {
  // ANIMATION INITIALIZATION
  useEffect(() => {
    window.triggerScroll();
  }, []);

  const { slug } = useParams();
  const { generalDataGlobal } = useContext(GlobalState);
  const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
  const [singlePageData, setSinglePageData] = useState();
  const [loader, setLoader] = useState(false);

  const relatedImages = [];

  useEffect(() => {
    setLoader(true);
    setGeneralDataApiOptions({
      url: "projects/" + slug,
      callback: (res) => {
        setSinglePageData(res.data);
        setLoader(false);
      },
    });
  }, [slug]); // eslint-disable-line react-hooks/exhaustive-deps

  if (singlePageData?.gallery != null) {
    JSON.parse(singlePageData?.gallery).forEach((element) => {
      relatedImages.push(process.env.REACT_APP_BASE_URL + "" + element);
    });
  }

  return (
    <>
      <Api options={generalDataApiOptions} />

      {loader ? (
        <div className="custom-loader middle-page"></div>
      ) : (
        <Layout active="OUR WORK">
          <Helmet>
            <title>{singlePageData?.title}</title>
            <meta
              name="title"
              content={singlePageData?.title}
            />
            <meta
              name="description"
              content={generalDataGlobal[0]?.seo_pages[3].description}
            />
            <meta
              property="og:title"
              content={singlePageData?.title}
            />
            <meta
              property="og:description"
              content={generalDataGlobal[0]?.seo_pages[3].description}
            />
            <meta
              property="og:image"
              content={generalDataGlobal[0]?.seo_pages[3].image}
            />
            <meta
              property="twitter:title"
              content={singlePageData?.title}
            />
            <meta
              property="twitter:description"
              content={generalDataGlobal[0]?.seo_pages[3].description}
            />
            <meta
              property="twitter:image"
              content={generalDataGlobal[0]?.seo_pages[3].image}
            />
          </Helmet>
            <ImageBanner
              title={generalDataGlobal[0].project_settings.banner_title}
              text={singlePageData?.subtitle}
              image={
                process.env.REACT_APP_BASE_URL +
                singlePageData?.image
              }
            />
        

          {/* SECTION 1 */}
          <div className="container py-5 my-5">
            <div className="row justify-content-center">
              <div className="col-sm-10" animate="down">
                <h3 className="wwa-sec-title text-center text-capitalize">
                  {singlePageData?.title}
                </h3>
                <div
                  className="wwa-sec-text text-center mt-4"
                  dangerouslySetInnerHTML={{
                    __html: singlePageData?.description,
                  }}
                ></div>
              </div>
            </div>
          </div>

          {/* SECTION 2 */}
          {relatedImages?.length > 0 ? (
            <RelatedWork
              title="related photos"
              bgImage={
                process.env.REACT_APP_BASE_URL +
                "" +
                generalDataGlobal[0].project_settings.related_work_image
              }
              workContent={relatedImages}
              smallTitle={true}
              gallery={true}
            />
          ) : null}

          {/* SECTION 3 */}
          {singlePageData?.project_list.length > 0 ? (
            <div className="container py-4">
              <div className="row justify-content-center">
                {singlePageData?.project_list.map((project, index) => (
                  <div
                    key={index}
                    className={`col-sm-10 ${
                      index + 1 !== singlePageData?.project_list.length
                        ? "mb-3"
                        : ""
                    }`}
                    animate="left"
                  >
                    <Link
                      to={"/our-work/" + project.slug}
                      className="text-decoration-none"
                    >
                      <WorkCard
                        title={project.title}
                        text={project.excerpt}
                        image={
                          process.env.REACT_APP_BASE_URL + "" + project.image
                        }
                      />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </Layout>
      )}
    </>
  );
};

export default OurWorkSingle;
