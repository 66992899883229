import React, { useState, useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import Api from "../components/api";

// COMPONENTS IMPORTS
import BubbleBanner from "../components/BubbleBanner";
import ImageBanner from "../components/ImageBanner";
import Layout from "../components/Layout";
import GlobalState from "../contexts/GlobalState";

const Contact = () => {
  const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
  const [errorMessages, setErrorMessages] = useState();
  const [successMessages, setSuccessMessages] = useState();

  // FORM DATA
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [showErrors, setShowErrors] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(true);

  const { generalDataGlobal } = useContext(GlobalState);

  useEffect(() => {
    if (generalDataGlobal) setLoader(false);

  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const formSubmitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    setGeneralDataApiOptions({
      url: "/contact",
      method: "post",
      data: {
        name: name,
        email: email,
        phone: phone,
        subject: subject,
        message: message,
      },
      callback: (res) => {
        setLoading(false);
        setName("");
        setEmail("");
        setPhone("");
        setSubject("");
        setMessage("");
        setErrorMessages("");
        setShowErrors(false);
        setShowSuccess(true);
        setSuccessMessages(
          generalDataGlobal[0]?.contact_settings.success_message
        );
        setTimeout(() => {
          setSuccessMessages("");
        }, 3000);
      },
      catch422: (errors) => {
        setLoading(false);
        setSuccessMessages("");
        setErrorMessages(errors);
        setShowErrors(true);
      },
    });
  }; // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <>
        <Api options={generalDataApiOptions} />
        {loader ? (
          <div className="custom-loader middle-page"></div>
        ) : (
          <Layout active="LET’S TALK" contact={true}>
            <Helmet>

              <title>{generalDataGlobal[0]?.seo_pages[5].title}</title>
              <meta
                name="title"
                content={generalDataGlobal[0]?.seo_pages[5].title}
              />
              <meta
                name="description"
                content={generalDataGlobal[0]?.seo_pages[5].description}
              />
              <meta
                property="og:title"
                content={generalDataGlobal[0]?.seo_pages[5].title}
              />
              <meta
                property="og:description"
                content={generalDataGlobal[0]?.seo_pages[5].description}
              />
              <meta
                property="og:image"
                content={generalDataGlobal[0]?.seo_pages[5].image}
              />
              <meta
                property="twitter:title"
                content={generalDataGlobal[0]?.seo_pages[5].title}
              />
              <meta
                property="twitter:description"
                content={generalDataGlobal[0]?.seo_pages[5].description}
              />
              <meta
                property="twitter:image"
                content={generalDataGlobal[0]?.seo_pages[5].image}
              />

            </Helmet>
            {generalDataGlobal[0]?.contact_settings.banner_image != null ? (
              <ImageBanner
                title={generalDataGlobal[0].contact_settings.banner_title}
                text={generalDataGlobal[0].contact_settings.banner_subtitle}
                image={
                  process.env.REACT_APP_BASE_URL +
                  generalDataGlobal[0].contact_settings.banner_image
                }
              />
            ) : (
              <BubbleBanner
                title={generalDataGlobal[0].contact_settings.banner_title}
                subtitle={generalDataGlobal[0].contact_settings.banner_subtitle}
                id="contact"
              />
            )}

            {/* SECTION 1 */}
            <div className="position-relative">
              <div className="container py-5">
                <div className="row py-5">
                  <div className="col-lg-1"></div>
                  <div className="col-md-5" animate="left">
                    <div className="contact-info d-flex flex-column">
                      <a
                        target="_blank"
                        rel="noreferrer noopener"
                        href={
                          generalDataGlobal[0].contact_settings.location_url
                        }
                      >
                        <div
                          className="text-capitalize contact-info mb-0 text-break"
                          dangerouslySetInnerHTML={{
                            __html:
                              generalDataGlobal[0].contact_settings.location,
                          }}
                        ></div>
                      </a>
                      <a
                        href={`mailto: ${generalDataGlobal[0].contact_settings.email}`}
                        className="text-capitalize contact-info-hover text-break"
                      >
                        {generalDataGlobal[0].contact_settings.email}
                      </a>
                      <a
                        href={`tel:${generalDataGlobal[0].contact_settings.phone}`}
                        className="text-capitalize contact-info-hover text-break"
                      >
                        {generalDataGlobal[0].contact_settings.phone}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* MAP ON DESKTOP */}
              <div
                className="position-absolute top-0 end-0 w-50 h-100 ratio d-md-block d-none"
                animate="right"
                dangerouslySetInnerHTML={{
                  __html: generalDataGlobal[0].contact_settings.map_url
                }}
              >

                {/* <iframe
                  title="map"
                  src={
                    "https://maps.google.com/maps?q=" +
                    generalDataGlobal[0].contact_settings.map_coordinates1 +
                    "," +
                    generalDataGlobal[0].contact_settings.map_coordinates2 +
                    "&hl=en&z=14&output=embed"
                  }
                  // src={
                  //   "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d907.712476137365!2d" +
                  //   generalDataGlobal[0].contact_settings.map_coordinates2 +
                  //   "!3d" +
                  //   generalDataGlobal[0].contact_settings.map_coordinates1 +
                  //   "!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7dec4ba41f114736!2zMjTCsDI5JzI2LjQiTiA1NMKwMjEnNTAuMCJF!5e0!3m2!1sen!2slb!4v1659353761565!5m2!1sen!2slb"
                  // }
                ></iframe> */}
              </div>
            </div>
            {/* MAP ON MOBILE */}
            <div className="container-fluid d-md-none" animate="right">
              <div className="row">
                <div className="col-12 px-0 contact-map-mob" dangerouslySetInnerHTML={{
                  __html: generalDataGlobal[0].contact_settings.map_url
                }}>
                  {/* <iframe
                    title="map"
                    className="w-100 h-100"
                    src={
                      "https://maps.google.com/maps?q=" +
                      generalDataGlobal[0].contact_settings.map_coordinates1 +
                      "," +
                      generalDataGlobal[0].contact_settings.map_coordinates2 +
                      "&hl=en&z=14&output=embed"
                    }
                  ></iframe> */}
                </div>
              </div>
            </div>

            {/* SECTION 2 */}
            <div className="bg-gray">
              <div className="container py-5" animate="left">
                <div className="row justify-content-center py-5">
                  <div className="col-10">
                    <h3 className="contact-form-title">
                      {generalDataGlobal[0].contact_settings.form_title}
                    </h3>
                  </div>
                  <div className="col-10">
                    <form onSubmit={formSubmitHandler} className="contact-form">
                      <div className="row">
                        <div className="col-md-6 mt-md-0 mt-3">
                          <input
                            type="text"
                            placeholder={
                              generalDataGlobal[0].contact_settings
                                .name_placeholder
                            }
                            id="full-name"
                            value={name}
                            onChange={(e) => {
                              setName(e.target.value);
                            }}
                          />
                        </div>
                        <div className="col-md-6 mt-md-0 mt-3">
                          <input
                            type="email"
                            placeholder={
                              generalDataGlobal[0].contact_settings
                                .email_placeholder
                            }
                            id="email"
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="row mt-md-3 mt-md-0 mt-3">
                        <div className="col-md-6">
                          <input
                            type="number"
                            placeholder={
                              generalDataGlobal[0].contact_settings
                                .phone_placeholder
                            }
                            id="full-name"
                            value={phone}
                            onChange={(e) => {
                              setPhone(e.target.value);
                            }}
                          />
                        </div>
                        <div className="col-md-6 mt-md-0 mt-3">
                          <input
                            type="text"
                            placeholder={
                              generalDataGlobal[0].contact_settings
                                .subject_placeholder
                            }
                            id="text"
                            value={subject}
                            onChange={(e) => {
                              setSubject(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="row mt-md-3 mt-md-0 mt-3">
                        <div className="col-12">
                          <textarea
                            type="text"
                            placeholder={
                              generalDataGlobal[0].contact_settings
                                .message_placeholder
                            }
                            id="text"
                            value={message}
                            onChange={(e) => {
                              setMessage(e.target.value);
                            }}
                          />
                        </div>
                      </div>

                      {showErrors
                        ? Object.values(errorMessages).map((er, index) => (
                          <div key={index}>
                            <p className="text-red">{er}</p>
                          </div>
                        ))
                        : null}

                      {showSuccess ? (
                        <div>
                          <p className="">{successMessages}</p>
                        </div>
                      ) : null}

                      {loading ? <div className="custom-loader"></div> : null}

                      <div className="d-flex justify-content-end">
                        <button
                          type="submit"
                          className="red-button-border w-fit text-uppercase mt-3"
                          disabled={loading}
                        >
                          {generalDataGlobal[0].contact_settings.submit_text}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Layout>
        )}
      </>
    </div>
  );
};

export default Contact;
