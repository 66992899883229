import React from 'react'

const TitleDevider = (props) => {
  // This function is to divide the title between two different stylings
  const splitTitles = (title) => {
    const firstWord = title.split(' ')[0]
    const restText = title.split(firstWord + ' ')
    return { firstWord, restText }
  }
  return (
    <div
      className={`title d-flex ${
        props.center ? 'justify-content-center' : ''
      } ${props.smallTitle === true ? 'small-title' : ''}`}
    >
      <h1 className={`text-uppercase ${props.title_white ? 'text-white' : ''}`}>
        {splitTitles(props.title).firstWord}
        <span className="text-uppercase title-outlined">&nbsp;{splitTitles(props.title).restText}</span>
      </h1>
    </div>
  )
}

export default TitleDevider
