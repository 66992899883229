import React, { useContext, useEffect, useState } from "react";

// CUSTOM IMPORTS
import BubblesBackgroundWrapper from "../components/BubblesBackgroundWrapper";
import ImageBanner from "../components/ImageBanner";
import Layout from "../components/Layout";
import BubbleBanner from "../components/BubbleBanner";

// IMAGES IMPORTS
import nextButton from "../assets/fixed-images/next-button.svg";

// DUMMY IMAGES
import GlobalState from "../contexts/GlobalState";
import { Helmet } from "react-helmet";

const WhoWeAre = () => {
  const [loader, setLoader] = useState(true);
  // ANIMATION INITIALIZATION
  useEffect(() => {
    setLoader(true);
    window.triggerScroll();
    if (generalDataGlobal) setLoader(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  var isSafari =
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === "[object SafariRemoteNotification]";
    })(
      !window["safari"] ||
        (typeof safari !== "undefined" && window["safari"].pushNotification)
    );

  const { generalDataGlobal } = useContext(GlobalState);

  return (
    <div>
      {loader ? (
        <div className="custom-loader middle-page"></div>
      ) : (
        <Layout active="WHO WE ARE">
          <Helmet>
            <title>{generalDataGlobal[0]?.seo_pages[1].title}</title>
            <meta
              name="title"
              content={generalDataGlobal[0]?.seo_pages[1].title}
            />
            <meta
              name="description"
              content={generalDataGlobal[0]?.seo_pages[1].description}
            />
            <meta
              property="og:title"
              content={generalDataGlobal[0]?.seo_pages[1].title}
            />
            <meta
              property="og:description"
              content={generalDataGlobal[0]?.seo_pages[1].description}
            />
            <meta
              property="og:image"
              content={generalDataGlobal[0]?.seo_pages[1].image}
            />
            <meta
              property="twitter:title"
              content={generalDataGlobal[0]?.seo_pages[1].title}
            />
            <meta
              property="twitter:description"
              content={generalDataGlobal[0]?.seo_pages[1].description}
            />
            <meta
              property="twitter:image"
              content={generalDataGlobal[0]?.seo_pages[1].image}
            />
          </Helmet>
          {generalDataGlobal[0].who_we_are.banner_image != null ? (
            <ImageBanner
              title={generalDataGlobal[0].who_we_are.banner_title}
              text={generalDataGlobal[0].who_we_are.banner_subtitle}
              image={
                process.env.REACT_APP_BASE_URL +
                generalDataGlobal[0].who_we_are.banner_image
              }
            />
          ) : (
            <BubbleBanner
              title={generalDataGlobal[0].who_we_are.banner_title}
              subtitle={generalDataGlobal[0].who_we_are.banner_subtitle}
              id="wwa"
            />
          )}

          {/* SECTION 1 */}
          <div className="position-relative py-5">
            <div className="container py-5 my-lg-5 position-relative front">
              <div className="row justify-content-center py-5">
                <div className="col-lg-6 col-sm-9 col-11" animate="left">
                  <h3 className="wwa-sec-title text-center text-capitalize">
                    {generalDataGlobal[0].who_we_are.section1_title}
                  </h3>
                  <div
                    className="wwa-sec-text text-center mt-4"
                    dangerouslySetInnerHTML={{
                      __html:
                        generalDataGlobal[0].who_we_are.section1_description,
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <BubblesBackgroundWrapper
              withHuman={false}
              white={true}
              id="section1"
            />
          </div>

          {/* SECTION 2 */}
          <div className="position-relative py-5 overflow-hidden">
            <div className="container py-5 my-lg-5">
              <div className="row justify-content-center">
                <div className="col-lg-10" animate="down">
                  <div className="row justify-content-between">
                    <div className="col-lg-5">
                      <h3 className="wwa-sec-title text-white">
                        {generalDataGlobal[0].who_we_are.section2_title}
                      </h3>
                      <div className="wwa-sec2-img-mob d-flex align-items-center mt-lg-0 mt-5 mx-auto d-lg-none">
                        <div className="wwa-sec2-border ratio ratio-1x1 overflow-hidden">
                          <img
                            src={
                              process.env.REACT_APP_BASE_URL +
                              generalDataGlobal[0].who_we_are.section2_image
                            }
                            alt="services"
                            className="cover"
                          />
                        </div>
                      </div>
                      <div
                        className="wwa-sec-text text-white mt-4"
                        dangerouslySetInnerHTML={{
                          __html:
                            generalDataGlobal[0].who_we_are
                              .section2_description,
                        }}
                      ></div>
                      <a href={generalDataGlobal[0].who_we_are.section2_url} className="text-decoration-none">
                        <div className="d-flex wwa-sec2-button-hover">
                          <div className="wwa-sec2-button d-flex align-items-center justify-content-end">
                            <p className="text-uppercase px-4 mb-0 wwa-sec2-button-text">
                              {generalDataGlobal[0].who_we_are.section2_button}
                            </p>
                          </div>
                          <div className="wwa-sec2-arrow ratio ratio-1x1 flex-shrink-0 ms-2">
                            <img
                              src={nextButton}
                              alt="arrow"
                              className="contain"
                            />
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="col-xl-5 col-sm-6 d-lg-flex align-items-center mt-lg-0 mt-5 d-none">
                      <div className="wwa-sec2-border ratio ratio-1x1 overflow-hidden">
                        <img
                          src={
                            process.env.REACT_APP_BASE_URL +
                            generalDataGlobal[0].who_we_are.section2_image
                          }
                          alt="services"
                          className="cover"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="position-absolute top-0 start-0 back wwa-sec2-bg"
              style={{
                backgroundImage: `url(${
                  process.env.REACT_APP_BASE_URL +
                  generalDataGlobal[0].who_we_are.section2_bg
                })`,
                backgroundAttachment: isSafari ? "scroll" : "fixed",
              }}
            ></div>
          </div>
        </Layout>
      )}
    </div>
  );
};

export default WhoWeAre;
