import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

// CUSTOM COMPONENTS
import Layout from "../components/Layout";
import BubbleBanner from "../components/BubbleBanner";
import WhatWeDoCard from "../components/whatWeDoCard";

//DUMMY IMAGES

import GlobalState from "../contexts/GlobalState";
import ImageBanner from "../components/ImageBanner";
import Api from "../components/api";
import { Helmet } from "react-helmet";

const WhatWeDo = () => {
  // ANIMATION INITIALIZATION
  useEffect(() => {
    window.triggerScroll();
  }, []);

  const { generalDataGlobal } = useContext(GlobalState);

  const [generalDataApiOptions, setGeneralDataApiOptions] = useState({});
  const [services, setServices] = useState();
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    setGeneralDataApiOptions({
      url: "services",
      callback: (res) => {
        setServices(res.data.services);
        setLoader(false);
      },
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Api options={generalDataApiOptions} />
      {loader ? (
        <div className="custom-loader middle-page"></div>
      ) : (
        <Layout active="WHAT WE DO">
          <Helmet>
            <title>{generalDataGlobal[0]?.seo_pages[2].title}</title>
            <meta
              name="title"
              content={generalDataGlobal[0]?.seo_pages[2].title}
            />
            <meta
              name="description"
              content={generalDataGlobal[0]?.seo_pages[2].description}
            />
            <meta
              property="og:title"
              content={generalDataGlobal[0]?.seo_pages[2].title}
            />
            <meta
              property="og:description"
              content={generalDataGlobal[0]?.seo_pages[2].description}
            />
            <meta
              property="og:image"
              content={generalDataGlobal[0]?.seo_pages[2].image}
            />
            <meta
              property="twitter:title"
              content={generalDataGlobal[0]?.seo_pages[2].title}
            />
            <meta
              property="twitter:description"
              content={generalDataGlobal[0]?.seo_pages[2].description}
            />
            <meta
              property="twitter:image"
              content={generalDataGlobal[0]?.seo_pages[2].image}
            />
          </Helmet>
          {generalDataGlobal[0].services_settings.banner_image != null ? (
            <ImageBanner
              title={generalDataGlobal[0].services_settings.banner_title}
              text={generalDataGlobal[0].services_settings.banner_excerpt}
              image={
                process.env.REACT_APP_BASE_URL + generalDataGlobal[0].services_settings.banner_image
              }
            />
          ) : (
            <BubbleBanner
              title={generalDataGlobal[0].services_settings.banner_title}
              subtitle={generalDataGlobal[0].services_settings.banner_excerpt}
              id="wwa"
            />
          )}
          <div className="container py-5 my-5">
            <div className="row justify-content-center">
              {services?.map((service, index) => (
                
                <div
                  key={index}
                  className={(services?.length === 7 ? "col-lg-3 col-md-4 col-sm-6 wwd-item-w py-3" : (services?.length % 3 === 0 || services?.length % 5 === 0 || services?.length === 13 || services?.length === 17) ? "col-md-4 col-sm-6 wwd-item-w py-3" : "col-lg-3 col-md-4 col-sm-6 wwd-item-w py-3")}
                  animate="down"
                >

                  <Link to={service.slug}>
                    <WhatWeDoCard
                    index={index}
                      image={process.env.REACT_APP_BASE_URL + service.icon}
                      text_1={service.title}
                      text_2={service.excerpt}
                    />
                  </Link>
                </div>

              ))}
            </div>
          </div>
        </Layout>
      )}
    </>
  );
};

export default WhatWeDo;
