import React from 'react'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

const CountryCode = (props) => {
    return (
        <PhoneInput
            placeholder={props.placeholder}
            value={props.state}
            onChange={props.onChange}
        />
    )
}

export default CountryCode